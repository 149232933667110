import React, { useEffect } from 'react';
import classes from './RecentViewed.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useApp } from '../../hooks/useApp';
import ItemCard from '../ui/item-card/ItemCard';
import { getRecents } from '../../services/app.service';
import { toast } from 'react-toastify';
import NoData from '../ui/no-data/NoData';
import Loading from '../ui/loading/Loading';
import { APP_URL } from '../../constants';
export default function RecentViewed() {
  const { recents } = useApp();

  useEffect(() => {
    //if (recents.loaded) return;
    (async () => {
      recents.setLoading(true);
      const { error, message, recents: recentsData } = await getRecents();
      recents.setLoading(false);
      if (error) {
        toast.error(message);
        return;
      }

      recents.set(recentsData);
    })();
  }, []);

  return (
    <div
      className={`mt-4 mb-4 p-4 text-black rounded ${classes['recent-section']}`}>
      <h1 className='title font__bold mb-4'>Recent Viewed</h1>
      <Row className={classes['row']}>
        <Loading state={recents.loading} />
        {!recents.loading && recents.list.length === 0 && (
          <NoData
            title='No Recent dashboards!'
            subTitle='There are no recent dashboards yet. Keep exploring the application.'
          />
        )}
        {!recents.loading &&
          recents.list.map((r) => {
            // if (r.parent_menu_id === 0 || !r.dashboard_name) return;
            const reportUrl =
              r.IS_EXTERNAL === 'Y'
                ? r.REPORT_URL
                : `${APP_URL}/${r.CATEGORY_SLUG}/${r.REPORT_SLUG}`;
            return (
              <Col
                lg={4}
                md={6}
                sm={12}
                className='mb-2'
                key={r.REPORT_ID}>
                <ItemCard
                  layout='card'
                  title={r.REPORT_NAME}
                  appName={r.CATEGORY_NAME}
                  id={r.REPORT_ID}
                  link={reportUrl}
                  isExternal={r.IS_EXTERNAL === 'Y'}
                  icon='dashboard_black'
                />
              </Col>
            );
          })}
      </Row>
    </div>
  );
}
