// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z1ZDAudyHCDMAgZZ5h2y{min-height:calc(100vh - 363px);max-height:calc(100vh - 260px);overflow-y:auto}.YuhkP6WoXj7lsgwZ8cM7{text-align:left !important}.YuhkP6WoXj7lsgwZ8cM7:not(h3 label){font-weight:500}.YuhkP6WoXj7lsgwZ8cM7 input~label{display:flex;align-items:center}.rWf7aDERK0cc06w92yMQ{margin-bottom:10px;font-size:17px;position:sticky;top:0px;background:#fff;z-index:99;padding-block:0px;padding-right:15px}.rWf7aDERK0cc06w92yMQ label{font-weight:600 !important;display:flex;align-items:center}.p0eu1Pcsp6VwXVbIrG2J{margin-bottom:20px}.p0eu1Pcsp6VwXVbIrG2J .Jjm75E4rs2AlZh5jIGxY{color:var(--color-gray-3) !important}.p0eu1Pcsp6VwXVbIrG2J>div{border-left:0;border-right:0;cursor:pointer}.p0eu1Pcsp6VwXVbIrG2J>div:hover{background-color:var(--color-light-brown)}`, "",{"version":3,"sources":["webpack://./src/components/reports/MapRolesToReports.module.scss"],"names":[],"mappings":"AAAA,sBACE,8BAAA,CACA,8BAAA,CACA,eAAA,CAEF,sBACE,0BAAA,CACA,oCACE,eAAA,CAGF,kCACE,YAAA,CACA,kBAAA,CAGJ,sBACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,OAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CACA,4BACE,0BAAA,CACA,YAAA,CACA,kBAAA,CAIJ,sBACE,kBAAA,CAEA,4CACE,oCAAA,CAIF,0BACE,aAAA,CACA,cAAA,CACA,cAAA,CAEF,gCACE,yCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapReportList": `Z1ZDAudyHCDMAgZZ5h2y`,
	"checkbox": `YuhkP6WoXj7lsgwZ8cM7`,
	"reportGroup": `rWf7aDERK0cc06w92yMQ`,
	"reportList": `p0eu1Pcsp6VwXVbIrG2J`,
	"inactive": `Jjm75E4rs2AlZh5jIGxY`
};
export default ___CSS_LOADER_EXPORT___;
