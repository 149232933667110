import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/styles.scss';
import './App.scss';
import { toast } from 'react-toastify';
import PublicRoutes from './routes/PublicRoutes';
import AdminRoutes from './routes/AdminRoutes';
import DynamicRoutes from './routes/DynamicRoutes';
import NotFound from './pages/not-found/NotFound';
import { useApp } from './hooks/useApp';
import { getUnixId, initApp } from './utils';

function App() {
  const { menus, user, dashboards } = useApp();
  const [routes, setRoutes] = useState([]);
  const [routeLoaded, setRouteLoaded] = useState(false);
  const unixId = getUnixId();

  useEffect(() => {
    (async () => {
      if (unixId) {
        try {
          dashboards.setLoading(true);
          const routesData = await initApp(unixId, {
            menus: { set: menus.set },
            user,
            dashboards: { set: dashboards.set },
          });
          dashboards.setLoading(false);
          if (!routesData) {
            return toast.error('Unable to load dynamic menus for this user.');
          }
          setRoutes(routesData);
          setRouteLoaded(true);
        } catch (e) {
          toast.error('Somthing went wrong while initiating app.');
        }
      }
    })();
  }, []);

  return (
    <div className='mfe-app'>
      <Routes>
        {PublicRoutes()}
        {DynamicRoutes({ routes })}
        {AdminRoutes()}
        {routeLoaded && (
          <Route
            path='*'
            element={<NotFound />}
          />
        )}
      </Routes>
    </div>
  );
}

export default App;
