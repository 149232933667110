import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Layout from './components/layout/Layout';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from './store/store';
import NotAuthorized from './components/not-authorized/NotAuthorized';
import { useFetchUser } from './hooks/user/useFetchUser';
import Loading from './components/ui/loading/Loading';
export default function Root() {
  const { isAuthorized, loading } = useFetchUser();

  if (loading)
    return (
      <Loading
        state={true}
        className='mt-2'
      />
    );

  if (!loading && !isAuthorized) {
    return <NotAuthorized />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className='d-flex'>
          <Layout />
          <App />
        </div>
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
        />
      </BrowserRouter>
    </Provider>
  );
}
