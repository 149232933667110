import axios from 'axios';

const defaultOptions = {
  baseURL: `${process.env.REACT_APP_API_HOST}/digital-api/`,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosClient = () => {
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
};

export default axiosClient();
