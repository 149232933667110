import React from 'react';
import classes from './NotAuthorized.module.scss';
import notFoundImg from '../../assets/images/app/not-found.png';

export default function NotAuthorized() {
  return (
    <div
      className={`custom-bg text-dark text-center d-flex flex-column align-items-center justify-content-center px-2 ${classes.notAuth}`}>
      <img
        src={notFoundImg}
        className={classes.notAuthImage}
      />
      <h1 className='title title__xxxx-large fw-bold'>
        Dear Digital Insight User,
      </h1>

      <p className='mt-2 mb-1'>
        Oops! It seems like your access hasn&apos;t been set up or you
        don&apos;t have permission for this tool.
      </p>
      <p className='mb-1'>
        For any inquiries or further assistance, please do not hesitate to reach
        out to our support team.
      </p>
      <p> Thank you for your cooperation.</p>
    </div>
  );
}
