import React from 'react';
import classes from './Favorites.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { useApp } from '../../hooks/useApp';
import ItemCard from '../ui/item-card/ItemCard';
import NoData from '../ui/no-data/NoData';
import Loading from '../ui/loading/Loading';
import { APP_URL } from '../../constants';

export default function FavoritesList() {
  const { dashboards } = useApp();
  const hasFavoriteDashboards = structuredClone(dashboards.list).some(
    (d) => d.IS_FAVORITE === 'Yes',
  );
  return (
    <Container fluid>
      <Row className={`${classes['row']} mt-4`}>
        <Loading state={dashboards.loading} />
        {!dashboards.loading && !hasFavoriteDashboards && (
          <NoData
            title='No Favorites dashboards!'
            subTitle='There are no favorites dashboards yet. Mark your most used dashboards to favorites by clicking on the star.'
          />
        )}
        {dashboards.list.map((f) => {
          if (f.IS_FAVORITE === 'No') return;
          const reportUrl =
            f.IS_EXTERNAL === 'Y'
              ? f.REPORT_URL
              : `${APP_URL}/${f.CATEGORY_SLUG}/${f.REPORT_SLUG}`;
          return (
            <Col
              key={f.REPORT_ID}
              lg={4}
              md={6}
              sm={12}>
              <ItemCard
                layout='list'
                title={f.REPORT_NAME}
                appName={f.CATEGORY_NAME}
                id={f.REPORT_ID}
                link={reportUrl}
                isExternal={f.IS_EXTERNAL === 'Y'}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
