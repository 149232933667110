import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import { toast } from 'react-toastify';
import { useApp } from '../../../hooks/useApp';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { createUser, updateUser } from '../../../services/manage-user.service';
import { getAllRoles } from '../../../services/manage-role.service';

export default function CreateUserForm({
  users,
  fetchUsers,
  setUsers,
  data = {
    UNIX_ID: '',
    NAME: '',
    EMAIL: '',
    ROLE_ID: '',
  },
  actionType = 'create',
  handleClose,
}) {
  const { user } = useApp();
  const [userData, setUserData] = useState(data);
  const [roles, setRoles] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      UNIX_ID: userData?.UNIX_ID || '',
      NAME: userData?.NAME || '',
      EMAIL: userData?.EMAIL || '',
      UPDATED_BY: user?.unixId,
      ROLE_ID: userData?.ID || '',
    },
    mode: 'onBlur',
  });

  const onChangeHandler = (value, type) => {
    setUserData((prev) => ({ ...prev, [type]: value }));
    setValue(type, value);
  };

  useEffect(() => {
    setValue('ROLE_ID', userData?.ROLE_ID);
  }, [userData?.ROLE_ID, setValue]);

  const submitHandler = async (data) => {
    const actions = {
      create: createUser,
      edit: updateUser,
    };

    data.UPDATED_BY = user.unixId;
    try {
      if (actionType === 'edit') {
        data.UNIX_ID = userData.UNIX_ID;
      }

      const {
        error,
        message,
        user: responseData,
      } = await actions[actionType](data);
      if (error) {
        toast.error(message);
        return;
      }
      if (actionType === 'create') {
        fetchUsers();
      } else {
        const temp = [...users];
        const index = temp.findIndex((a) => a.UNIX_ID === responseData.UNIX_ID);
        temp[index] = responseData;
        setUsers(temp);
      }
      toast.success(message);
      handleClose();
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchRoles = async () => {
      try {
        const { error, roles: rolesData } = await getAllRoles();
        if (error) {
          if (isMounted) toast.error(rolesData);
          return;
        }
        if (isMounted) setRoles(rolesData);
      } catch (e) {
        if (isMounted) toast.error(e.message);
      }
    };

    fetchRoles();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Form.Group className='mb-3'>
        <Form.Label>Unix Id</Form.Label>
        <Form.Control
          type='text'
          disabled={actionType === 'edit'}
          placeholder='Enter unix id'
          {...register('UNIX_ID', {
            required: 'Unix Id is required',
          })}
          onChange={(e) => onChangeHandler(e.target.value, 'UNIX_ID')}
        />
        {errors.UNIX_ID && (
          <Form.Text className='text-danger'>
            {errors.UNIX_ID.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type='text'
          placeholder='Enter user name'
          {...register('NAME', {
            required: 'Name is required',
          })}
          onChange={(e) => onChangeHandler(e.target.value, 'NAME')}
        />
        {errors.NAME && (
          <Form.Text className='text-danger'>{errors.NAME.message}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type='email'
          placeholder='Enter user email'
          {...register('EMAIL', {
            required: 'Email is required',
          })}
          onChange={(e) => onChangeHandler(e.target.value, 'EMAIL')}
        />
        {errors.EMAIL && (
          <Form.Text className='text-danger'>{errors.EMAIL.message}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Role</Form.Label>
        <Form.Select
          {...register('ROLE_ID', {
            required: 'Role is required',
          })}
          value={userData?.ROLE_ID || ''}
          onChange={(e) => onChangeHandler(e.target.value, 'ROLE_ID')}>
          <option
            value=''
            disabled>
            Select a role
          </option>
          {roles.map((role) => (
            <option
              key={role.ID}
              value={role.ID}>
              {role.ROLE_CODE}
            </option>
          ))}
        </Form.Select>
        {errors.ID && (
          <Form.Text className='text-danger'>{errors.ID.message}</Form.Text>
        )}
      </Form.Group>

      <Button type='submit'>
        {actionType === 'create' ? 'Create' : 'Update'}
      </Button>
    </Form>
  );
}

CreateUserForm.propTypes = {
  users: PropTypes.array.isRequired,
  setUsers: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  data: PropTypes.shape({
    UNIX_ID: PropTypes.string,
    NAME: PropTypes.string,
    EMAIL: PropTypes.string,
    ROLE_ID: PropTypes.number,
    UPDATED_BY: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['create', 'edit']),
  handleClose: PropTypes.func.isRequired,
};

CreateUserForm.defaultProps = {
  data: {
    UNIX_ID: '',
    NAME: '',
    EMAIL: '',
    ROLE_ID: null,
  },
  actionType: 'create',
};
