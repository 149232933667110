import React from 'react';
import Tile from '../tile/Tile';
import Bookmark from '../../bookmark/Bookmark';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';

export default function ItemCard({
  id,
  title,
  icon,
  layout = 'card',
  link,
  appName,
  isExternal = false,
  bookmark = true,
}) {
  return (
    <Tile
      type={layout}
      title={title}
      appName={appName}
      icon={icon}
      link={link}
      isExternal={isExternal}
      id={id}
      actions={
        <div className='d-flex align-items-center'>
          {isExternal && (
            <Icon
              name='externalLink'
              color='blue'
            />
          )}
          {bookmark && (
            <Bookmark
              id={id}
              title={title}
            />
          )}
        </div>
      }
    />
  );
}

ItemCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  layout: PropTypes.oneOf(['card', 'list']),
  link: PropTypes.string,
  appName: PropTypes.string,
  bookmark: PropTypes.bool,
  isExternal: PropTypes.bool,
};

ItemCard.defaultProps = {
  layout: 'card',
  link: '',
  appName: '',
  bookmark: true,
  isExternal: false,
};
