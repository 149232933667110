import React, { useEffect, useState } from 'react';
import Button from '../../../components/ui/button/Button';
import Modal from '../../../components/ui/modal/Modal';
import CreateCategoryForm from './CreateCategoryForm';
import CategoryList from './CategoryList';
import { getCategories } from '../../../services/manage-category.service';
import { toast } from 'react-toastify';
import Loading from '../../../components/ui/loading/Loading';
import Pagination from '../../../components/ui/pagination/Pagination';

const ManageCategoriesPage = () => {
  const [editData, setEditData] = useState(null);
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
  });

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const {
        error,
        message,
        categories: categoriesData,
      } = await getCategories({
        page: pagination.currentPage,
        pageSize: pagination.pageSize,
      });

      if (error) {
        toast.error(message);
        return;
      }

      setPagination((prev) => ({
        ...prev,
        totalPages: categoriesData.totalPages,
      }));
      setCategories(categoriesData.categories);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [pagination.currentPage, pagination.pageSize]);

  const handleClose = () => {
    setShow(false);
    setEditData(null);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (editData) {
      handleShow();
    }
  }, [editData]);

  return (
    <div className='py-4 admin-container'>
      <Button onClick={handleShow}>Add New Category</Button>
      <Modal
        show={show}
        onHide={handleClose}
        title={editData ? 'Update Category' : 'Add New Category'}
        hideFooter
        body={
          <CreateCategoryForm
            categories={categories}
            fetchCategories={fetchCategories}
            setCategories={setCategories}
            data={editData}
            actionType={editData ? 'edit' : 'create'}
            handleClose={handleClose}
          />
        }
      />
      <Loading state={loading} />
      <CategoryList
        categories={categories}
        setEditData={setEditData}
        fetchCategories={fetchCategories}
      />
      <Pagination
        currentPage={pagination.currentPage}
        onPageChange={handlePageChange}
        totalPages={pagination.totalPages}
      />
    </div>
  );
};

export default ManageCategoriesPage;
