import React from 'react';
import classes from './Loading.module.scss';
import PropTypes from 'prop-types';
export default function Loading({ state, className }) {
  return (
    state && (
      <div className={`${classes['lds-ripple']} ${className}`}>
        <div></div>
        <div></div>
      </div>
    )
  );
}
Loading.propTypes = {
  state: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: '',
};
