import React from 'react';
import { Table } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { deleteRole } from '../../../services/manage-role.service';
import { formatDate } from '../../../utils';

const deleteRoleHandler = async (id, fetchRoles) => {
  try {
    const { error, message } = await deleteRole(id);
    if (error) {
      toast.error(message);
      return;
    }
    fetchRoles();
    toast.success(message);
  } catch (error) {
    toast.error(error.message);
  }
};

const confirmDelete = (id, fetchRoles) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    showCancelButton: true,
    showCloseButton: true,
    closeButtonHtml:
      '<i class="btn-close close-button" style="margin-right:10px !important;"></i>',
    confirmButtonText: 'Yes, delete it!',
  }).then(async (result) => {
    if (result.isConfirmed) {
      await deleteRoleHandler(id, fetchRoles);
    }
  });
};

export default function RoleList({
  roles,
  setEditData,
  fetchRoles,
  openRoleMapHanlder,
}) {
  return (
    <Table
      hover
      className='mt-4'>
      <thead>
        <tr>
          <th className='text-nowrap'>Actions</th>
          <th className='text-nowrap'>Role Code</th>
          <th className='text-nowrap'>Role Description</th>
          <th className='text-nowrap'>Created Date</th>
          <th className='text-nowrap'>Updated Date</th>
          <th className='text-nowrap'>Updated By</th>
        </tr>
      </thead>
      <tbody>
        {roles.length === 0 && (
          <tr>
            <td
              colSpan={6}
              className='text-center'>
              No roles found
            </td>
          </tr>
        )}
        {roles?.map((role) => (
          <tr key={role.ID}>
            <td>
              <div className='d-flex gap-1'>
                <Button
                  className='me-1'
                  onClick={() => openRoleMapHanlder(role)}
                  title='Map Reports'>
                  <i className='icon icon__settings me-0'></i>
                </Button>
                <Button
                  className='me-1'
                  onClick={() => setEditData(role)}
                  title='Edit'>
                  <i className='icon icon__edit me-0'></i>
                </Button>
                <Button
                  onClick={() => confirmDelete(role.ID, fetchRoles)}
                  title='Delete'>
                  <i className='icon icon__delete me-0'></i>
                </Button>
              </div>
            </td>
            <td>{role.ROLE_CODE}</td>
            <td>{role.ROLE_DESC}</td>
            <td>{formatDate(role.CREATED_DATE)}</td>
            <td>{formatDate(role.UPDATED_DATE)}</td>
            <td>{role.UPDATED_BY}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

RoleList.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      ROLE_CODE: PropTypes.string.isRequired,
      ROLE_DESC: PropTypes.string.isRequired,
      CREATED_DATE: PropTypes.string.isRequired,
      UPDATED_DATE: PropTypes.string.isRequired,
      UPDATED_BY: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchRoles: PropTypes.func.isRequired,
  setEditData: PropTypes.func.isRequired,
  openRoleMapHanlder: PropTypes.func.isRequired,
};
