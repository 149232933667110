// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f73frdhh8tPo1UHZ15cY{width:100%;height:calc(100vh - 55px)}`, "",{"version":3,"sources":["webpack://./src/components/ui/iframe/Iframe.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe": `f73frdhh8tPo1UHZ15cY`
};
export default ___CSS_LOADER_EXPORT___;
