import React from 'react';
import classes from './UserProfile.module.scss';
import { Dropdown } from 'react-bootstrap';
import { useApp } from '../../hooks/useApp';
export default function UserProfile() {
  const { user } = useApp();

  return (
    user &&
    user.unixId &&
    user.loaded && (
      <Dropdown>
        <Dropdown.Toggle
          size='sm'
          className={classes.userInfo}>
          <div className={`title title__small font__bold ${classes.userName}`}>
            <span>{user?.name[0]?.toUpperCase()}</span>
            <i className='icon icon__chevronDown'></i>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className={classes.userMenu}>
          <div className={classes.user}>
            <i className='title font__non_italic title__small'>
              {user?.name[0]?.toUpperCase()}
            </i>
            <h2 className='title title__small mb-2 word-break line-height-normal'>
              {user?.name}
            </h2>
            <p className='title title__x-small mb-1 font__bold mb-0 word-break line-height-normal'>
              {user?.email}
            </p>
            <span className='title title__x-small font__bold title__grey'>
              {user?.role?.toUpperCase()}
            </span>
          </div>
          {/* <Dropdown.Item
            className={classes.dropdownItem}
            onClick={logoutHandler}>
            <i className='fa-sharp fa-solid fa-right-from-bracket me-2'></i>
            Sign Out
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    )
  );
}
