import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import { toast } from 'react-toastify';
import { useApp } from '../../../hooks/useApp';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { createRole, updateRole } from '../../../services/manage-role.service';

export default function CreateRoleForm({
  roles,
  setRoles,
  fetchRoles,
  data = {
    ID: '',
    ROLE_CODE: '',
    ROLE_DESC: '',
    UPDATED_BY: '',
  },
  actionType = 'create',
  handleClose,
}) {
  const { user } = useApp();
  const [roleData, setRoleData] = useState(data);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ID: roleData?.ID || '',
      ROLE_CODE: roleData?.ROLE_CODE || '',
      ROLE_DESC: roleData?.ROLE_DESC || '',
      UPDATED_BY: user?.unixId,
    },
    mode: 'onBlur',
  });

  const onChangeHandler = (value, type) => {
    setRoleData((prev) => ({ ...prev, [type]: value }));
    setValue(type, value);
  };

  const submitHandler = async (formData) => {
    const actions = {
      create: createRole,
      edit: updateRole,
    };

    formData.UPDATED_BY = user.unixId;

    try {
      if (actionType === 'edit') {
        formData.ID = roleData.ID;
      }

      const {
        error,
        message,
        role: responseData,
      } = await actions[actionType](formData);

      if (error) {
        toast.error(message);
        return;
      }

      if (actionType === 'create') {
        fetchRoles();
      } else {
        let updatedRoles = [];
        updatedRoles = roles.map((role) =>
          role.ID === responseData.ID ? { ...role, ...responseData } : role,
        );
        setRoles(updatedRoles);
      }

      toast.success(message);
      handleClose();
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    reset(roleData);
  }, [roleData, reset]);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Form.Group className='mb-3'>
        <Form.Label>Role Code</Form.Label>
        <Form.Control
          type='text'
          placeholder='Enter role code'
          {...register('ROLE_CODE', {
            required: 'Role code is required',
            maxLength: {
              value: 10,
              message: 'Role code cannot exceed 10 characters',
            },
          })}
          onChange={(e) => onChangeHandler(e.target.value, 'ROLE_CODE')}
        />
        {errors.ROLE_CODE && (
          <Form.Text className='text-danger'>
            {errors.ROLE_CODE.message}
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Role Description</Form.Label>
        <Form.Control
          type='text'
          placeholder='Enter role description'
          {...register('ROLE_DESC', {
            required: 'Role description is required',
          })}
          onChange={(e) => onChangeHandler(e.target.value, 'ROLE_DESC')}
        />
        {errors.ROLE_DESC && (
          <Form.Text className='text-danger'>
            {errors.ROLE_DESC.message}
          </Form.Text>
        )}
      </Form.Group>
      <Button type='submit'>
        {actionType === 'create' ? 'Create' : 'Update'}
      </Button>
    </Form>
  );
}

CreateRoleForm.propTypes = {
  roles: PropTypes.array.isRequired,
  setRoles: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  data: PropTypes.shape({
    ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ROLE_CODE: PropTypes.string,
    ROLE_DESC: PropTypes.string,
    UPDATED_BY: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['create', 'edit']),
  handleClose: PropTypes.func.isRequired,
};

CreateRoleForm.defaultProps = {
  data: {
    ID: null,
    ROLE_CODE: '',
    ROLE_DESC: '',
  },
  actionType: 'create',
};
