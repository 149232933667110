import React from 'react';
import TitleBar from '../../components/ui/titlebar/TitleBar';
import RecentViewed from '../../components/recent-viewed/RecentViewed';
import Favorites from '../../components/favorites/Favorites';

const HomePage = () => {
  return (
    <div className='p-4'>
      <TitleBar
        title='Omni-Channel Insights (OCI)'
        icon='home_black'
      />
      <RecentViewed />
      <Favorites />
    </div>
  );
};

export default HomePage;
