import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import Swal from 'sweetalert2';
import { deleteReport } from '../../../services/manage-report.service';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { formatDate } from '../../../utils';

const deleteReportHandler = async (reportId, fetchReports) => {
  try {
    const { error, message } = await deleteReport(reportId);
    if (error) {
      toast.error(message);
      return;
    }
    fetchReports();
    toast.success(message);
  } catch (error) {
    toast.error(error.message);
  }
};

const confirmDelete = (reportId, fetchReports) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(async (result) => {
    if (result.isConfirmed) {
      await deleteReportHandler(reportId, fetchReports);
    }
  });
};

export default function ReportList({ reports, setEditData, fetchReports }) {
  return (
    <Table
      hover
      className='mt-4'>
      <thead>
        <tr>
          <th className='text-nowrap'>Actions</th>
          <th className='text-nowrap'>Category Name</th>
          <th className='text-nowrap'>Report Name</th>
          <th className='text-nowrap'>Report URL</th>
          <th className='text-nowrap'>Is External</th>
          <th className='text-nowrap'>Is Active</th>
          <th className='text-nowrap'>App Source</th>
          <th className='text-nowrap'>Created Date</th>
          <th className='text-nowrap'>Updated Date</th>
          <th className='text-nowrap'>Updated By</th>
        </tr>
      </thead>
      <tbody>
        {reports.length === 0 && (
          <tr>
            <td
              colSpan={9}
              className='text-center'>
              No Reports found
            </td>
          </tr>
        )}
        {reports?.map((report) => (
          <tr key={report.ID}>
            <td>
              <div className='d-flex gap-1'>
                <Button
                  className='me-1'
                  onClick={() => setEditData(report)}
                  title='Edit report'
                  variant='icon'>
                  <i className='icon icon__edit me-0'></i>
                </Button>
                <Button
                  onClick={() => confirmDelete(report.ID, fetchReports)}
                  variant='icon'
                  title='Delete report'>
                  <i className='icon icon__delete me-0'></i>
                </Button>
              </div>
            </td>
            <td>{report.CATEGORY_NAME}</td>
            <td>{report.REPORT_NAME}</td>
            <td>
              <a
                href={report.REPORT_URL}
                target='_blank'
                title={report.REPORT_URL}
                className='td-max'
                rel='noreferrer'>
                {report.REPORT_URL}
              </a>
            </td>
            <td>
              {report.IS_EXTERNAL === 'Y' ? (
                <Badge
                  bg='primary'
                  className='d-inline-flex align-items-center gap-2'>
                  Yes
                </Badge>
              ) : (
                <Badge bg='secondary'>No</Badge>
              )}
            </td>
            <td>
              {report.IS_ACTIVE === 'Y' ? (
                <Badge bg='success'>Active</Badge>
              ) : (
                <Badge bg='danger'>Inactive</Badge>
              )}
            </td>
            <td>{report.APP_SOURCE}</td>
            <td>{formatDate(report.CREATED_DATE)}</td>
            <td>{formatDate(report.UPDATED_DATE)}</td>
            <td>{report.UPDATED_BY}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

ReportList.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      REPORT_NAME: PropTypes.string.isRequired,
      REPORT_URL: PropTypes.string.isRequired,
      IS_EXTERNAL: PropTypes.string.isRequired,
      IS_ACTIVE: PropTypes.string,
      APP_SOURCE: PropTypes.string.isRequired,
      CREATED_DATE: PropTypes.string,
      UPDATED_DATE: PropTypes.string,
      UPDATED_BY: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setEditData: PropTypes.func.isRequired,
  fetchReports: PropTypes.func.isRequired,
};
