import React, { useEffect, useState } from 'react';
import Button from '../../../components/ui/button/Button';
import { toast } from 'react-toastify';
import Loading from '../../../components/ui/loading/Loading';
import Modal from '../../../components/ui/modal/Modal';
import { getUsers } from '../../../services/manage-user.service';
import UserList from './UserList';
import CreateUserForm from './CreateUserForm';
import Pagination from '../../../components/ui/pagination/Pagination';

export default function ManageUsersPage() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [editData, setEditData] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  });

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const {
        error,
        message,
        users: usersData,
      } = await getUsers({
        page: pagination.currentPage,
        pageSize: pagination.pageSize,
      });

      if (error) {
        toast.error(message);
        return;
      }

      setUsers(usersData.users);

      // Update pagination state with the total pages
      setPagination((prev) => ({
        ...prev,
        totalPages: usersData.totalPages,
      }));
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [pagination.currentPage, pagination.pageSize]);

  const handleClose = () => {
    setShow(false);
    setEditData(null);
  };

  useEffect(() => {
    if (editData) {
      handleShow();
    }
  }, [editData]);

  const handleShow = () => setShow(true);

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
    }));
  };

  return (
    <div className='py-4 admin-container'>
      <Button onClick={handleShow}>Add New User</Button>
      <Modal
        show={show}
        onHide={handleClose}
        title={editData ? 'Update User' : 'Add New User'}
        hideFooter
        body={
          <CreateUserForm
            users={users}
            setUsers={setUsers}
            fetchUsers={fetchUsers}
            data={editData}
            actionType={editData ? 'edit' : 'create'}
            handleClose={handleClose}
          />
        }
      />
      <Loading state={loading} />
      <UserList
        users={users}
        fetchUsers={fetchUsers}
        setEditData={setEditData}
      />
      <Pagination
        currentPage={pagination.currentPage}
        onPageChange={handlePageChange}
        totalPages={pagination.totalPages}
      />
    </div>
  );
}
