// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.REk0Mo4TXjYqbPOCS2Ge{text-align:center}.REk0Mo4TXjYqbPOCS2Ge img{width:100px;height:auto}.REk0Mo4TXjYqbPOCS2Ge h3{font-weight:bold;margin-top:10px}.REk0Mo4TXjYqbPOCS2Ge p{font-weight:normal;max-width:70%;margin-left:auto;margin-right:auto}`, "",{"version":3,"sources":["webpack://./src/components/ui/no-data/NoData.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,0BACE,WAAA,CACA,WAAA,CAEF,yBACE,gBAAA,CACA,eAAA,CAEF,wBACE,kBAAA,CACA,aAAA,CACA,gBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodata": `REk0Mo4TXjYqbPOCS2Ge`
};
export default ___CSS_LOADER_EXPORT___;
