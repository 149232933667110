import React from 'react';
import { Card } from 'react-bootstrap';
import classes from './Tile.module.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { addToRecentView } from '../../../services/app.service';
export default function Tile({
  type = 'card',
  title,
  icon,
  actions,
  isExternal,
  link,
  appName,
  id,
}) {
  const trackHandler = () => {
    addToRecentView(id);
  };
  return link ? (
    <Card className={`${type === 'card' ? classes['card'] : classes['list']}`}>
      <Card.Body className='d-flex justify-content-between'>
        {isExternal ? (
          <a
            href={link}
            target='_blank'
            rel='noreferrer'
            onClick={trackHandler}
            className={classes.linkCard}>
            <Card.Text
              as='div'
              className={`mb-0 ${classes['card-title']} title title__small`}
              title={title}>
              {icon && <i className={`icon icon__${icon} nav-link-icon`}></i>}
              <div className={classes.content}>
                {appName && <h6>{appName}</h6>}
                <p>{title}</p>
              </div>
            </Card.Text>
          </a>
        ) : (
          <Link
            to={link}
            className={classes.linkCard}>
            <Card.Text
              as='div'
              className={`mb-0 ${classes['card-title']} title title__small`}
              title={title}>
              {icon && <i className={`icon icon__${icon} nav-link-icon`}></i>}
              <div className={classes.content}>
                {appName && <h6>{appName}</h6>}
                <p>{title}</p>
              </div>
            </Card.Text>
          </Link>
        )}

        <Card.Link href='#'>{actions}</Card.Link>
      </Card.Body>
    </Card>
  ) : (
    <Card className={`${type === 'card' ? classes['card'] : classes['list']}`}>
      <Card.Body className='d-flex justify-content-between'>
        <Card.Text
          as='div'
          className={`mb-0 ${classes['card-title']} title title__small`}
          title={title}>
          {icon && <i className={`icon icon__${icon} nav-link-icon`}></i>}
          <div className={classes.content}>
            {appName && <h6>{appName}</h6>}
            <p>{title}</p>
          </div>
        </Card.Text>
        <Card.Link href='#'>{actions}</Card.Link>
      </Card.Body>
    </Card>
  );
}

Tile.propTypes = {
  type: PropTypes.oneOf(['card', 'list']),
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  id: PropTypes.number,
  actions: PropTypes.node,
  link: PropTypes.string,
  appName: PropTypes.string,
  isExternal: PropTypes.bool,
};

Tile.defaultProps = {
  type: 'card',
  icon: null,
  actions: null,
  link: null,
  appName: null,
};
