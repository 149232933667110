import React from 'react';
import PropTypes from 'prop-types';
import classes from './Button.module.scss';
import { Button as BSButton } from 'react-bootstrap';
export default function Button({
  type,
  onClick,
  children,
  className,
  disabled,
  variant,
  ...rest
}) {
  return (
    <BSButton
      type={type}
      className={`${classes['button']} ${className} ${variant === 'icon' ? classes['btn-icon'] : ''}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}>
      {children}
    </BSButton>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['icon', 'default']),
};
