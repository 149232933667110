// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QmrOTIz1lpcHqzdjIMuD ._T1ZUyfHhGMVa81VtosQ{background:var(--color-tertiary-blue) 0% 0% no-repeat padding-box;border-radius:4px;font:normal normal bold 14px/16px Gene Sans;letter-spacing:0px;color:var(--color-white);border:none}.QmrOTIz1lpcHqzdjIMuD ._T1ZUyfHhGMVa81VtosQ:hover,.QmrOTIz1lpcHqzdjIMuD ._T1ZUyfHhGMVa81VtosQ:focus,.QmrOTIz1lpcHqzdjIMuD ._T1ZUyfHhGMVa81VtosQ:active{background:var(--color-tertiary-blue) 0% 0% no-repeat padding-box;border-radius:4px;font:normal normal bold 14px/16px Gene Sans;letter-spacing:0px;color:var(--color-white);border:none}.QmrOTIz1lpcHqzdjIMuD ._7gdhWvGQsKOjTow17mXx i{width:22px;height:19px;margin:0;background-size:contain;background-position:center;filter:invert(1)}.QmrOTIz1lpcHqzdjIMuD .aTQavkVHGn9lQWdYY0lu{background-color:var(--color-tertiary-blue)}.QmrOTIz1lpcHqzdjIMuD .aTQavkVHGn9lQWdYY0lu i{filter:invert(0)}.QmrOTIz1lpcHqzdjIMuD .BheTd79b0MhGF8BtMwZJ{display:flex;max-width:100%;flex-wrap:wrap;padding-inline:10px;gap:5px}.QmrOTIz1lpcHqzdjIMuD .BheTd79b0MhGF8BtMwZJ a{display:inline-flex;padding:10px;border-radius:8px;width:42px;height:42px;justify-content:center;align-items:center}.QmrOTIz1lpcHqzdjIMuD .BheTd79b0MhGF8BtMwZJ a:hover{background-color:var(--color-gray-2)}.QmrOTIz1lpcHqzdjIMuD .BheTd79b0MhGF8BtMwZJ a:focus,.QmrOTIz1lpcHqzdjIMuD .BheTd79b0MhGF8BtMwZJ a:active{background:var(--color-gray-2)}`, "",{"version":3,"sources":["webpack://./src/components/icon-selector/IconSelector.module.scss"],"names":[],"mappings":"AACE,4CACE,iEAAA,CACA,iBAAA,CACA,2CAAA,CACA,kBAAA,CACA,wBAAA,CACA,WAAA,CACA,uJAGE,iEAAA,CACA,iBAAA,CACA,2CAAA,CACA,kBAAA,CACA,wBAAA,CACA,WAAA,CAIF,+CACE,UAAA,CACA,WAAA,CACA,QAAA,CACA,uBAAA,CACA,0BAAA,CACA,gBAAA,CAOJ,4CACE,2CAAA,CACA,8CACE,gBAAA,CAIJ,4CACE,YAAA,CACA,cAAA,CACA,cAAA,CACA,mBAAA,CACA,OAAA,CACA,8CACE,mBAAA,CACA,YAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,oDACE,oCAAA,CAEF,yGAEE,8BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `QmrOTIz1lpcHqzdjIMuD`,
	"dropdownBtn": `_T1ZUyfHhGMVa81VtosQ`,
	"dropdownItem": `_7gdhWvGQsKOjTow17mXx`,
	"dropdownItemSelected": `aTQavkVHGn9lQWdYY0lu`,
	"menuWrap": `BheTd79b0MhGF8BtMwZJ`
};
export default ___CSS_LOADER_EXPORT___;
