import PropTypes from 'prop-types';
import React from 'react';

export default function Icon({
  name = 'home',
  color = 'white',
  className = '',
  ...rest
}) {
  return (
    <i
      {...rest}
      title={name}
      className={`icon icon__${name}${color === 'white' ? '' : `_${color}`} nav-link-icon ${className}`}></i>
  );
}
Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};
