import React, { useEffect, useState } from 'react';
import Button from '../../../components/ui/button/Button';
import { toast } from 'react-toastify';
import Loading from '../../../components/ui/loading/Loading';
import Modal from '../../../components/ui/modal/Modal';
import { getRoles } from '../../../services/manage-role.service';
import RoleList from './RoleList';
import CreateRoleForm from './CreateRoleForm';
import MapRolesToReports from '../../../components/reports/MapRolesToReports';
import Pagination from '../../../components/ui/pagination/Pagination';

export default function ManageRolesPage() {
  const [show, setShow] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [editData, setEditData] = useState(null);
  const [role, setRole] = useState(null);

  // Single Pagination State
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  });

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const {
        error,
        message,
        roles: rolesData,
      } = await getRoles({
        page: pagination.currentPage,
        pageSize: pagination.pageSize,
      });

      if (error) {
        toast.error(message);
        return;
      }

      setRoles(rolesData.roles);
      setPagination((prev) => ({
        ...prev,
        totalPages: rolesData.totalPages, // Update total pages dynamically
      }));
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [pagination.currentPage, pagination.pageSize]);

  const handleClose = () => {
    setShow(false);
    setEditData(null);
  };

  const handleMapShow = (role) => {
    setRole(role);
    setShowMap(true);
  };

  const handleMapClose = () => {
    setShowMap(false);
    setRole(null);
  };

  useEffect(() => {
    if (editData) {
      handleShow();
    }
  }, [editData]);

  const handleShow = () => setShow(true);

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  return (
    <div className='py-4 admin-container'>
      <Button onClick={handleShow}>Add New Role</Button>
      <Modal
        show={show}
        onHide={handleClose}
        title={editData ? 'Update Role' : 'Add New Role'}
        hideFooter
        body={
          <CreateRoleForm
            roles={roles}
            setRoles={setRoles}
            fetchRoles={fetchRoles}
            data={editData}
            actionType={editData ? 'edit' : 'create'}
            handleClose={handleClose}
          />
        }
      />
      <Loading state={loading} />
      <RoleList
        roles={roles}
        fetchRoles={fetchRoles}
        setEditData={setEditData}
        openRoleMapHanlder={handleMapShow}
      />
      {showMap && (
        <MapRolesToReports
          role={role}
          show={showMap}
          onClose={handleMapClose}
          onShow={handleMapShow}
        />
      )}
      <Pagination
        currentPage={pagination.currentPage}
        onPageChange={handlePageChange}
        totalPages={pagination.totalPages}
      />
    </div>
  );
}
