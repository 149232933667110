import React, { useEffect, useState } from 'react';
import { Badge, Form, ListGroup } from 'react-bootstrap';
import classes from './MapRolesToReports.module.scss';
import { useApp } from '../../hooks/useApp';
import NoData from '../ui/no-data/NoData';
import Modal from '../ui/modal/Modal';
import { toast } from 'react-toastify';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  editRoleMappings,
  getAllReports,
} from '../../services/manage-report.service';
import Icon from '../ui/icon/Icon';

export default function MapRolesToReports({ role, show, onClose }) {
  const { user } = useApp();
  const [initialMappings, setInitialMappings] = useState({});
  const [list, setList] = useState([]);
  const [reportGroup, setReportGroup] = useState(
    _.groupBy(list, 'CATEGORY_NAME'),
  );
  const [updatedMappings, setUpdatedMappings] = useState({
    add_mappings: [],
    remove_mappings: [],
  });

  const handleMappingChange = (e, id) => {
    const isChecked = e.target.checked;
    const temp = { ...updatedMappings };

    if (isChecked) {
      temp.remove_mappings = temp.remove_mappings.filter((a) => a !== id);
      if (!initialMappings[id]) temp.add_mappings.push(id);
    } else {
      temp.add_mappings = temp.add_mappings.filter((a) => a !== id);
      if (initialMappings[id]) temp.remove_mappings.push(id);
    }

    setUpdatedMappings(temp);
  };

  const handleCategoryChange = (e, categoryName) => {
    const isChecked = e.target.checked;
    const temp = { ...updatedMappings };

    reportGroup[categoryName].forEach((report) => {
      const id = report.ID;
      if (isChecked) {
        // Add to add_mappings and remove from remove_mappings
        temp.remove_mappings = temp.remove_mappings.filter((a) => a !== id);
        if (!initialMappings[id]) temp.add_mappings.push(id);
      } else {
        // Add to remove_mappings and remove from add_mappings
        temp.add_mappings = temp.add_mappings.filter((a) => a !== id);
        if (initialMappings[id]) temp.remove_mappings.push(id);
      }
    });

    setUpdatedMappings(temp);
  };

  const isCategoryChecked = (categoryName) => {
    return reportGroup[categoryName].every(
      (report) =>
        updatedMappings.add_mappings.includes(report.ID) ||
        (!updatedMappings.remove_mappings.includes(report.ID) &&
          initialMappings[report.ID]),
    );
  };

  const isReportChecked = (report) => {
    return (
      updatedMappings.add_mappings.includes(report.ID) ||
      (!updatedMappings.remove_mappings.includes(report.ID) &&
        initialMappings[report.ID])
    );
  };

  useEffect(() => {
    (async () => {
      try {
        if (list.length > 0) return;
        const {
          error,
          message,
          reports: reportsData,
        } = await getAllReports(role.ID);
        if (error) {
          toast.error(message);
          return;
        }
        const sortedList = reportsData.sort((a, b) => a.UX_ORDER - b.UX_ORDER);
        setList(sortedList);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [role.ID]);

  console.log('list', list);
  useEffect(() => {
    setReportGroup(_.groupBy(list, 'CATEGORY_NAME'));
  }, [list]);

  useEffect(() => {
    if (list.length > 0) {
      setInitialMappings(
        list.reduce((acc, report) => {
          acc[report.ID] = report.IS_MAPPED === true;
          return acc;
        }, {}),
      );
    }
  }, [list]);

  const onSubmitHandler = async () => {
    const add_mappings = updatedMappings.add_mappings.filter(
      (a) => !initialMappings[a],
    );
    const remove_mappings = updatedMappings.remove_mappings.filter(
      (a) => initialMappings[a],
    );

    if (add_mappings.length === 0 && remove_mappings.length === 0) {
      toast.info('No changes to save.');
      return;
    }

    try {
      const { error, message } = await editRoleMappings(
        role.ID,
        updatedMappings,
        user.unixId,
      );
      if (error) {
        toast.error(message);
        return;
      }

      const tempReports = structuredClone(list);
      const tempList = tempReports.map((r) => {
        if (updatedMappings.add_mappings.includes(r.ID)) {
          r.IS_MAPPED = true;
        }
        if (updatedMappings.remove_mappings.includes(r.ID)) {
          r.IS_MAPPED = false;
        }
        return r;
      });

      const sortedList = tempList.sort((a, b) => a.UX_ORDER - b.UX_ORDER);
      setList(sortedList);
      setUpdatedMappings({
        add_mappings: [],
        remove_mappings: [],
      });
      toast.success(message);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <Modal
      size='xl'
      show={show}
      onHide={onClose}
      onSave={onSubmitHandler}
      title={`Reports mapped to ${role.ROLE_CODE}`}
      disableSave={
        updatedMappings.add_mappings.length > 0 ||
        updatedMappings.remove_mappings.length > 0
          ? false
          : true
      }
      body={
        <>
          <Form>
            <div className={classes.mapReportList}>
              <ListGroup variant='flush'>
                {list.length === 0 && (
                  <NoData
                    title='No Reports Available!'
                    subTitle='There are no reports available for mapping. Contact your administrator for more information.'
                  />
                )}
                {Object.keys(reportGroup).map((categoryName, i) => (
                  <React.Fragment key={i}>
                    <h3 className={classes.reportGroup}>
                      <Form.Check
                        reverse
                        type='checkbox'
                        id={`category-${i}`}
                        label={categoryName}
                        className={classes.checkbox}
                        checked={isCategoryChecked(categoryName)}
                        onChange={(e) => handleCategoryChange(e, categoryName)}
                      />
                    </h3>
                    <div className={classes.reportList}>
                      {reportGroup[categoryName].map((report) => (
                        <ListGroup.Item
                          className={`${report.IS_ACTIVE === 'N' ? classes.inactive : ''}`}
                          key={report.ID}>
                          <Form.Check
                            reverse
                            type='checkbox'
                            checked={isReportChecked(report)}
                            id={report.ID}
                            label={
                              <>
                                {report.REPORT_NAME}{' '}
                                {report.IS_EXTERNAL === 'Y' && (
                                  <Icon
                                    name='externalLink'
                                    color='blue'
                                    className='ms-1 me-0'
                                  />
                                )}
                                {report.IS_ACTIVE === 'N' && (
                                  <Badge
                                    bg='danger'
                                    className='ms-2'>
                                    Inactive
                                  </Badge>
                                )}
                              </>
                            }
                            className={classes.checkbox}
                            onChange={(e) => handleMappingChange(e, report.ID)}
                          />
                        </ListGroup.Item>
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </ListGroup>
            </div>
          </Form>
        </>
      }
    />
  );
}

MapRolesToReports.propTypes = {
  role: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
