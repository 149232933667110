// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R4wd8UNqtTTvR363rBil,.R4wd8UNqtTTvR363rBil div{box-sizing:border-box;color:var(--color-tertiary-blue)}.R4wd8UNqtTTvR363rBil{display:block;position:relative;width:80px;height:80px;margin:auto}.R4wd8UNqtTTvR363rBil div{position:absolute;border:4px solid currentColor;opacity:1;border-radius:50%;animation:R4wd8UNqtTTvR363rBil 1s cubic-bezier(0, 0.2, 0.8, 1) infinite}.R4wd8UNqtTTvR363rBil div:nth-child(2){animation-delay:-0.5s}@keyframes R4wd8UNqtTTvR363rBil{0%{top:36px;left:36px;width:8px;height:8px;opacity:0}4.9%{top:36px;left:36px;width:8px;height:8px;opacity:0}5%{top:36px;left:36px;width:8px;height:8px;opacity:1}100%{top:0;left:0;width:80px;height:80px;opacity:0}}`, "",{"version":3,"sources":["webpack://./src/components/ui/loading/Loading.module.scss"],"names":[],"mappings":"AAAA,gDAEE,qBAAA,CACA,gCAAA,CAEF,sBACE,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CAEF,0BACE,iBAAA,CACA,6BAAA,CACA,SAAA,CACA,iBAAA,CACA,uEAAA,CAEF,uCACE,qBAAA,CAEF,gCACE,GACE,QAAA,CACA,SAAA,CACA,SAAA,CACA,UAAA,CACA,SAAA,CAEF,KACE,QAAA,CACA,SAAA,CACA,SAAA,CACA,UAAA,CACA,SAAA,CAEF,GACE,QAAA,CACA,SAAA,CACA,SAAA,CACA,UAAA,CACA,SAAA,CAEF,KACE,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lds-ripple": `R4wd8UNqtTTvR363rBil`
};
export default ___CSS_LOADER_EXPORT___;
