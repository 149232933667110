import api from '../../api';
import { createErrorResponse, createSuccessResponse } from '../utils';
export async function getUsers({ page = 1, pageSize = 10 }) {
  try {
    const userResponse = await api.get(
      `/users?page=${page}&pageSize=${pageSize}`,
    );
    if (userResponse.data.status === 'error') {
      return createErrorResponse(userResponse);
    }
    return createSuccessResponse(userResponse, 'users');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function createUser(data) {
  try {
    const userResponse = await api.post(`/users`, data);
    if (userResponse.data.status === 'error') {
      return createErrorResponse(userResponse);
    }
    return createSuccessResponse(userResponse, 'user');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function updateUser(data) {
  try {
    const userResponse = await api.put(`/users`, data);
    if (userResponse.data.status === 'error') {
      return createErrorResponse(userResponse);
    }
    return createSuccessResponse(userResponse, 'user');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function deleteUser(id) {
  try {
    const userResponse = await api.delete(`/users/${id}`);
    if (userResponse.data.status === 'error') {
      return createErrorResponse(userResponse);
    }
    return createSuccessResponse(userResponse, 'user');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}
