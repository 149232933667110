import React from 'react';
import { Breadcrumb as BSBreadcrumb } from 'react-bootstrap';
import classes from './Breadcrumb.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { createBreadcrumbFromPath } from '../../../utils';
import PropTypes from 'prop-types';

export default function Breadcrumb({ appName, dashboardName }) {
  let prevPath = '';
  const { pathname } = useLocation();
  const breadcrumb = createBreadcrumbFromPath(pathname);
  return (
    <BSBreadcrumb className={classes.breadcrumb}>
      <BSBreadcrumb.Item
        linkAs={breadcrumb.length > 1 ? Link : 'span'}
        linkProps={{ to: `${APP_URL}/home` }}>
        Home
      </BSBreadcrumb.Item>
      {breadcrumb.map((p, i) => {
        if (i === 0 && p === 'home') return;
        let el = (
          <BSBreadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `${APP_URL}${prevPath}/${p}` }}
            key={p}>
            {appName}
          </BSBreadcrumb.Item>
        );
        if (i === breadcrumb.length - 1) {
          el = (
            <BSBreadcrumb.Item
              linkAs='span'
              key={p}>
              {dashboardName}
            </BSBreadcrumb.Item>
          );
        }
        prevPath = `${prevPath}/${p}`;
        return el;
      })}
    </BSBreadcrumb>
  );
}

Breadcrumb.propTypes = {
  appName: PropTypes.string.isRequired,
  dashboardName: PropTypes.string.isRequired,
};
