import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  loading: false,
};

const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenus: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    addMenu: (state, action) => {
      state.list.push(action.payload);
    },
    deleteMenu: (state, action) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },
    setMenuLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setMenus, addMenu, deleteMenu, setMenuLoading } =
  menusSlice.actions;
export default menusSlice.reducer;
