// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gD00mPe2hLqcuklAul0M{display:flex;align-items:baseline}.gD00mPe2hLqcuklAul0M h3{margin-right:10px}.SVtQOhwfou_7_4LbuxAa{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/components/ui/titlebar/Titlebar.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oBAAA,CAGF,yBACE,iBAAA,CAGF,sBACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titlebar": `gD00mPe2hLqcuklAul0M`,
	"action-btns": `SVtQOhwfou_7_4LbuxAa`
};
export default ___CSS_LOADER_EXPORT___;
