import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import favoritesReducer from './slices/favoritesSlice';
import recentsReducer from './slices/recentsSlice';
import menusReducer from './slices/menusSlice';
import dashboardsReducer from './slices/dashboardsSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    favorites: favoritesReducer,
    recents: recentsReducer,
    menus: menusReducer,
    dashboards: dashboardsReducer,
  },
});

export default store;
