// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VGqbtDvdtGmr7jE65Hpm{height:calc(100vh - 310px);max-height:calc(100vh - 310px);overflow-y:auto}.TwLCkfyqoJPIG1_oTasE{text-align:left !important}.TwLCkfyqoJPIG1_oTasE:not(h3 label){font-weight:500}.LlhPrEyvyJjExGRpCnLc{margin-bottom:10px;font-size:17px;position:sticky;top:0;background:#fff;z-index:99;padding-block:0px;padding-right:15px}.LlhPrEyvyJjExGRpCnLc label{font-weight:600 !important}.p8jLdFKy6fy5t0_2ALcH{margin-bottom:20px}.p8jLdFKy6fy5t0_2ALcH>div,.p8jLdFKy6fy5t0_2ALcH>a{border-left:0;border-right:0;cursor:pointer}.p8jLdFKy6fy5t0_2ALcH>div:hover,.p8jLdFKy6fy5t0_2ALcH>a:hover{background-color:var(--color-light-brown)}`, "",{"version":3,"sources":["webpack://./src/components/favorites/AddToFavorites.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,8BAAA,CACA,eAAA,CAEF,sBACE,0BAAA,CACA,oCACE,eAAA,CAGJ,sBACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,KAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CACA,4BACE,0BAAA,CAIJ,sBACE,kBAAA,CAEA,kDAEE,aAAA,CACA,cAAA,CACA,cAAA,CAEF,8DAEE,yCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"favList": `VGqbtDvdtGmr7jE65Hpm`,
	"checkbox": `TwLCkfyqoJPIG1_oTasE`,
	"dashboardGroup": `LlhPrEyvyJjExGRpCnLc`,
	"dashboardList": `p8jLdFKy6fy5t0_2ALcH`
};
export default ___CSS_LOADER_EXPORT___;
