import React from 'react';
import TitleBar from '../../components/ui/titlebar/TitleBar';
import { Col, Row } from 'react-bootstrap';
import ItemCard from '../../components/ui/item-card/ItemCard';
import { APP_URL } from '../../constants';
import PropTypes from 'prop-types';

export default function AppLandingPage({ name, icon, path, dashboards }) {
  return (
    <div className='p-4'>
      <TitleBar
        title={name}
        icon={icon + '_black'}
      />
      <Row className='d-row mt-4'>
        {dashboards.map((r) => {
          return (
            <Col
              lg={4}
              md={6}
              sm={12}
              className='mb-2'
              key={r.REPORT_SLUG}>
              <ItemCard
                layout='card'
                link={
                  r.IS_EXTERNAL === 'Y'
                    ? r.REPORT_URL
                    : `${APP_URL}/${path}/${r.REPORT_SLUG}`
                }
                isExternal={r.IS_EXTERNAL === 'Y'}
                title={r.REPORT_NAME}
                icon='dashboard_black'
                id={r.ID}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

AppLandingPage.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  dashboards: PropTypes.arrayOf(
    PropTypes.shape({
      REPORT_SLUG: PropTypes.string.isRequired,
      REPORT_NAME: PropTypes.string.isRequired,
      ID: PropTypes.number.isRequired,
    }),
  ).isRequired,
};
