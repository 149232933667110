// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NrqwcK6fxloWUBPPcETc{display:flex;justify-content:center;margin-top:2rem;gap:1rem;align-items:baseline}`, "",{"version":3,"sources":["webpack://./src/components/ui/pagination/Pagination.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,eAAA,CACA,QAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `NrqwcK6fxloWUBPPcETc`
};
export default ___CSS_LOADER_EXPORT___;
