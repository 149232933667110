import React from 'react';
import classes from './LinkButton.module.scss';
import PropTypes from 'prop-types';

export default function LinkButton({ onClick, children, className, disabled }) {
  return (
    <button
      className={`${classes['link-button']} ${className}`}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
}

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
