// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jDfIHGOGljy5yi_y8tJT{height:calc(100vh - 48px)}.jDfIHGOGljy5yi_y8tJT .YjcSVGD8bGA845cLKBX6{width:200px;margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/components/not-authorized/NotAuthorized.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,4CACE,WAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notAuth": `jDfIHGOGljy5yi_y8tJT`,
	"notAuthImage": `YjcSVGD8bGA845cLKBX6`
};
export default ___CSS_LOADER_EXPORT___;
