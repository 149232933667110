import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classes from './Header.module.scss';
import UserProfile from '../../user-profile/UserProfile';
import Breadcrumb from '../../ui/breadcrumb/Breadcrumb';
import { useApp } from '../../../hooks/useApp';
import { Link, useLocation } from 'react-router-dom';
import Bookmark from '../../bookmark/Bookmark';
import { APP_URL } from '../../../constants';
import Help from '../../help/Help';
import Search from '../../search/Search';
import PropTypes from 'prop-types';

const Header = ({ setSearchHandler }) => {
  const { dashboards } = useApp();
  const location = useLocation();

  const currentDashboard = dashboards.list.find((d) => {
    const path = `${APP_URL}/${d.CATEGORY_SLUG}/${d.REPORT_SLUG}`;
    return location.pathname == path;
  });
  const isDashboardPage = !!currentDashboard?.REPORT_ID;

  return (
    <header className={classes['gne-header']}>
      <Row className='w-100'>
        <Col
          xs={9}
          className={classes['content-wrap']}>
          <Link
            to={`${APP_URL}/home`}
            className={classes['content-wrap']}>
            <div className='gene-logo'></div>
            <div className='divider-vertical divider__white'></div>
            <span className={`title title__base ${classes['app-name']}`}>
              OCI
            </span>
          </Link>
          {isDashboardPage && currentDashboard && (
            <Breadcrumb
              appName={currentDashboard.CATEGORY_NAME}
              dashboardName={currentDashboard.REPORT_NAME}
            />
          )}
          {isDashboardPage && currentDashboard && (
            <Bookmark
              id={currentDashboard.REPORT_ID}
              title={currentDashboard.REPORT_NAME}
              size='lg'
              icon={{
                default: 'favorite',
                active: 'favoriteActive',
              }}
            />
          )}
        </Col>
        <Col
          xs={3}
          className={classes['header-cta']}>
          <Help />
          <Search setShow={setSearchHandler} />
          <UserProfile />
        </Col>
      </Row>
    </header>
  );
};

Header.propTypes = {
  setSearchHandler: PropTypes.func.isRequired,
};
export default Header;
