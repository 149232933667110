// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rtOsYpnFFBeaTbYNaNBC>*{--bs-gutter-x: 0.5rem}`, "",{"version":3,"sources":["webpack://./src/components/favorites/Favorites.module.scss"],"names":[],"mappings":"AAAA,wBACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `rtOsYpnFFBeaTbYNaNBC`
};
export default ___CSS_LOADER_EXPORT___;
