import React from 'react';
import { Dropdown } from 'react-bootstrap';
import classes from './IconSelector.module.scss';
import { icons } from '../../constants';
import PropTypes from 'prop-types';
export default function IconSelector({ selectedIcon = 'feedback', onChange }) {
  return (
    <Dropdown className={classes.dropdown}>
      <Dropdown.Toggle
        size='sm'
        className={classes.dropdownBtn}>
        Select Icon
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={`${classes.dropdownMenu} icon-selector-dropdown-menu`}>
        <div className={classes.menuWrap}>
          {icons.map((i, idx) => {
            return (
              <Dropdown.Item
                title={i}
                onClick={() => {
                  onChange(i, 'app_icon');
                }}
                key={idx}
                className={`${classes.dropdownItem} ${selectedIcon === i ? classes.dropdownItemSelected : ''}`}>
                <i
                  className={`${
                    i.includes('black') ? classes.blackIcon : classes.whiteIcon
                  } icon icon__${i} nav-link-icon`}></i>
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

IconSelector.propTypes = {
  selectedIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
