export const publicRouteData = [
  {
    CATEGORY_NAME: 'Home',
    CATEGORY_SLUG: 'home',
    CATEGORY_ICON: 'home',
    disabled: false,
    REPORTS: [],
  },
];
export const adminRouteData = [
  {
    CATEGORY_NAME: 'Settings',
    CATEGORY_SLUG: 'settings',
    CATEGORY_ICON: 'settings',
    disabled: false,
    REPORTS: [],
  },
];
