// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q6zbExHmdu_JzXYP_2wn{border:none;padding:0;padding-bottom:1px;position:relative;background:none;display:flex;align-items:center;justify-content:center;width:20px;height:20px;border-radius:50%;margin-right:2px;margin-left:10px}.Q6zbExHmdu_JzXYP_2wn i{margin-right:0 !important;width:18px;height:16px}.Q6zbExHmdu_JzXYP_2wn i.qr1PdljU2XZw3sVu45b7{width:20px;height:17px}`, "",{"version":3,"sources":["webpack://./src/components/bookmark/Bookmark.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,SAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,gBAAA,CACA,gBAAA,CACA,wBACE,yBAAA,CACA,UAAA,CACA,WAAA,CAEF,6CACE,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookmarkBtn": `Q6zbExHmdu_JzXYP_2wn`,
	"lgIcon": `qr1PdljU2XZw3sVu45b7`
};
export default ___CSS_LOADER_EXPORT___;
