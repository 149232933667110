// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../assets/images/icon_menu_collapse.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IuLQze8yYgz5dAf1HeyS{background-color:rgba(0,0,0,0);border:none;padding:0;cursor:pointer}.aycOWzzM_MkCxw9F8Syi{height:100vh;min-width:260px !important;position:absolute !important;left:0 !important;border-right:0 !important}.eVXvM53qM1G9JfInl_1N{min-width:auto !important}.dIUNqaqsW6YLI8qQ97k5{color:var(--color-light-blue-2) !important}.YwRTtSh6C2unr4DwZcTY{text-decoration:none;color:#fff}.byawcCiLeiJyo44ztaXi{display:flex;justify-content:space-between;padding-right:5px}.byawcCiLeiJyo44ztaXi .UNeTH2lMHE_2hdpE9mKB{color:#fff;font-size:14px;cursor:pointer;display:flex;align-items:center;font-family:var(--font-gene-sans-bold)}.Zyfrfdw7vBV4MX7CR46N{margin-left:48px}.Hfd0aTBmO1IN4KHqD7X9{height:6px;width:10px;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-size:cover;background-repeat:no-repeat}.ij2Fc2Jx3ywJi0VhRc5a{display:flex;justify-content:space-between;padding-right:5px}.ij2Fc2Jx3ywJi0VhRc5a .Yha4IER3hOJj1rqvmRQM{color:#fff;font-size:14px;cursor:pointer;display:flex;align-items:center;font-family:var(--font-gene-sans-bold)}`, "",{"version":3,"sources":["webpack://./src/components/layout/sidebar/SideBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,8BAAA,CACA,WAAA,CACA,SAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,0BAAA,CACA,4BAAA,CACA,iBAAA,CACA,yBAAA,CAEF,sBACE,yBAAA,CAEF,sBACE,0CAAA,CAEF,sBACE,oBAAA,CACA,UAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,iBAAA,CACA,4CACE,UAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sCAAA,CAIJ,sBACE,gBAAA,CAGF,sBACE,UAAA,CACA,UAAA,CACA,wDAAA,CACA,qBAAA,CACA,2BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,iBAAA,CACA,4CACE,UAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-btn": `IuLQze8yYgz5dAf1HeyS`,
	"sidebar": `aycOWzzM_MkCxw9F8Syi`,
	"sidebarCollapsed": `eVXvM53qM1G9JfInl_1N`,
	"disabled": `dIUNqaqsW6YLI8qQ97k5`,
	"linkMenu": `YwRTtSh6C2unr4DwZcTY`,
	"user-info": `byawcCiLeiJyo44ztaXi`,
	"user-name": `UNeTH2lMHE_2hdpE9mKB`,
	"report-icon": `Zyfrfdw7vBV4MX7CR46N`,
	"navbar-collapse": `Hfd0aTBmO1IN4KHqD7X9`,
	"userInfo": `ij2Fc2Jx3ywJi0VhRc5a`,
	"userName": `Yha4IER3hOJj1rqvmRQM`
};
export default ___CSS_LOADER_EXPORT___;
