// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SdQNfdj9PU4A3Vc4GW3f{background-color:var(--color-light-brown)}.SdQNfdj9PU4A3Vc4GW3f .rCxzd7LxNPcGeqyrhqTp>*{--bs-gutter-x: 0.5rem}`, "",{"version":3,"sources":["webpack://./src/components/recent-viewed/RecentViewed.module.scss"],"names":[],"mappings":"AAAA,sBACE,yCAAA,CACA,8CACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recent-section": `SdQNfdj9PU4A3Vc4GW3f`,
	"row": `rCxzd7LxNPcGeqyrhqTp`
};
export default ___CSS_LOADER_EXPORT___;
