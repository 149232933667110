import React from 'react';
import classes from './NoData.module.scss';
import noDataImg from '../../../assets/images/app/nodata.png';
import PropTypes from 'prop-types';
export default function NoData({ title, subTitle }) {
  return (
    <div className={classes.nodata}>
      <img src={noDataImg} />
      <h3>{title}</h3>
      <p>{subTitle}</p>
    </div>
  );
}

NoData.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

NoData.defaultProps = {
  title: 'No Data Available',
  subTitle: 'There is no data to display at the moment.',
};
