import React from 'react';
import Iframe from '../../components/ui/iframe/Iframe';
import PropTypes from 'prop-types';
import { useRecentTracking } from '../../hooks/tracking/useRecentTracking';

export default function QlikDashboard({ id, url }) {
  useRecentTracking({ reportId: id });
  return <Iframe src={url}></Iframe>;
}

QlikDashboard.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};
