import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  loading: false,
  loaded: false,
};

const recentsSlice = createSlice({
  name: 'recents',
  initialState,
  reducers: {
    setRecent: (state, action) => {
      state.list = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    addRecent: (state, action) => {
      state.list.push(action.payload);
    },
    deleteRecent: (state, action) => {
      state.list = state.list.filter(
        (item) => item.dashboard_id !== action.payload.id,
      );
    },
    setRecentLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setRecent, addRecent, deleteRecent, setRecentLoading } =
  recentsSlice.actions;
export default recentsSlice.reducer;
