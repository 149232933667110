import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from 'react-pro-sidebar';
import NavSubMenu from './NavSubMenu';
import { APP_URL } from '../../../constants';
import classes from './SideBar.module.scss';
import PropTypes from 'prop-types';
import { addToRecentView } from '../../../services/app.service';

export default function SideBar({ items }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const categoryData = [...items];
  const [activeItem, setActiveItem] = useState('');

  const collapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  const trackHandler = (id) => {
    addToRecentView(id);
  };

  const handleMenuItemClick = (link, menu) => {
    setActiveItem(link);
    setCollapsed(true);
    if (menu?.IS_EXTERNAL === 'Y') trackHandler(menu?.ID);
  };

  const isActive = (link) => {
    const isActivePath =
      location.pathname === `${APP_URL}/${link}` ||
      (link === '/' && location.pathname === APP_URL);
    const defaultSelection =
      location.pathname === `${APP_URL}` && link === activeItem;
    return isActivePath || defaultSelection;
  };

  const getParentActiveStatus = (submenu) => {
    return submenu.some((item) => isActive(item.link)) || isActive('/');
  };

  return (
    <Sidebar
      backgroundColor={'var(--color-secondary-blue)'}
      rootStyles={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
      collapsed={collapsed}
      collapsedWidth='60px'
      height={'400px'}
      width={'auto'}
      toggled={!collapsed}
      breakPoint='all'
      onBackdropClick={() => {
        setCollapsed(true);
      }}
      className={`${classes.sidebar} ${collapsed ? classes.sidebarCollapsed : ''}`}>
      <Menu
        closeOnClick={true}
        renderExpandIcon={({ open }) => {
          return (
            !collapsed &&
            (open ? (
              <i className={`nav-link-icon icon icon__chevronUp`}></i>
            ) : (
              <i className={`nav-link-icon icon icon__chevronDown`}></i>
            ))
          );
        }}>
        <MenuItem
          rootStyles={{
            backgroundColor: 'var(--color-secondary-blue)',
            position: 'sticky',
            top: 0,
            zIndex: 9,
            ['.' + menuClasses.button]: {
              backgroundColor: 'var(--color-secondary-blue)',
              paddingRight: '10px !important',
              paddingLeft: '10px !important',
              margin: '0px 5px',
              '&:hover': {
                backgroundColor: 'var(--color-secondary-blue) !important',
              },
            },
            ['.' + menuClasses.label]: {
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '8px',
            },
            ['.' + menuClasses.menuItemRoot]: {
              padding: '10px 5px',
            },
          }}
          key='menuitem1'>
          <button
            className={classes['menu-btn']}
            onClick={collapseSidebar}>
            {!collapsed ? (
              <i className={'icon icon__menu-collapse'}></i>
            ) : (
              <i className={'icon icon__menu-expand'}></i>
            )}
          </button>
          {/* {!collapsed && (
            <div className={classes.userInfo}>
              <div className={classes.userName}>
                <i className="icon icon__user_avatar"></i>
                {userName || "User"}
              </div>
            </div>
          )} */}
        </MenuItem>
        {categoryData.map((item, index) => {
          const isParentActive =
            item.REPORTS && getParentActiveStatus(item.REPORTS);
          const isActiveItem = isActive(item.CATEGORY_SLUG);
          if (!item.CATEGORY_NAME) return;
          return !item?.REPORTS?.length ? (
            <MenuItem
              key={index}
              component={<Link to={`${APP_URL}/${item?.CATEGORY_SLUG}`} />}
              onClick={() => handleMenuItemClick(item?.CATEGORY_SLUG, item)}
              title={item.CATEGORY_NAME}
              rootStyles={{
                ['.' + menuClasses.button]: {
                  backgroundColor: isActiveItem
                    ? 'var(--color-tertiary-blue)'
                    : 'var(--color-secondary-blue)',
                  '&:hover': {
                    backgroundColor: 'var(--color-tertiary-blue) !important',
                  },
                },
              }}>
              <i
                title={item.CATEGORY_NAME}
                className={`icon icon__${item.CATEGORY_ICON} nav-link-icon`}></i>
              {!collapsed && (
                <span className={item?.disabled ? classes.disabled : ''}>
                  {item.CATEGORY_NAME}
                </span>
              )}
            </MenuItem>
          ) : (
            <SubMenu
              key={index}
              title={item.CATEGORY_NAME}
              label={
                <NavSubMenu
                  item={item}
                  collapsed={collapsed}
                />
              }
              component={
                <Link
                  to={`${APP_URL}/${item?.CATEGORY_SLUG}`}
                  className='ps-6'
                />
              }
              // onClick={() => handleMenuItemClick(item?.link)}
              rootStyles={{
                ['& > .' + menuClasses.button]: {
                  paddingRight: '10px !important',
                  backgroundColor: isParentActive
                    ? 'var(--color-tertiary-blue)'
                    : 'var(--color-secondary-blue)',
                  '&:hover': {
                    backgroundColor: 'var(--color-tertiary-blue) !important',
                  },
                },
                ['.' + menuClasses.label]: {
                  textOverflow: 'initial',
                },
                ['& > .' + menuClasses.CATEGORY_ICON]: {
                  backgroundImage:
                    "url('./icon_menu_engagement.png') !important",
                  width: '26px',
                  height: ' 26px',
                },
              }}>
              {item?.REPORTS &&
                item?.REPORTS?.map((menu, i) => {
                  const isSubMenuActive = isActive(menu?.REPORT_SLUG);
                  return (
                    <MenuItem
                      suffix={
                        menu.IS_EXTERNAL === 'Y' && (
                          <i className='icon icon__externalLink_alt'></i>
                        )
                      }
                      title={menu.REPORT_NAME}
                      component={
                        menu.IS_EXTERNAL === 'Y' ? (
                          <a
                            href={menu.REPORT_URL}
                            target='_blank'
                            rel='noreferrer'
                            className='external-link'
                          />
                        ) : (
                          <Link
                            to={`${APP_URL}/${item?.CATEGORY_SLUG}/${menu?.REPORT_SLUG}`}
                          />
                        )
                      }
                      onClick={() => {
                        handleMenuItemClick(menu?.REPORT_SLUG, menu);
                      }}
                      rootStyles={{
                        ['.' + menuClasses.button]: {
                          backgroundColor: isSubMenuActive
                            ? 'var(--color-tertiary-blue)'
                            : 'var(--color-secondary-blue)',
                          '&:hover': {
                            backgroundColor:
                              'var(--color-tertiary-blue) !important',
                          },
                        },
                      }}
                      key={i}>
                      {menu.REPORT_NAME}
                    </MenuItem>
                  );
                })}
            </SubMenu>
          );
        })}
      </Menu>
    </Sidebar>
  );
}

SideBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      CATEGORY_SLUG: PropTypes.string.isRequired,
      CATEGORY_NAME: PropTypes.string.isRequired,
      CATEGORY_ICON: PropTypes.string.isRequired,
      REPORTS: PropTypes.arrayOf(
        PropTypes.shape({
          REPORT_SLUG: PropTypes.string.isRequired,
          REPORT_NAME: PropTypes.string.isRequired,
        }),
      ),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
};
