import { toast } from 'react-toastify';
import { APP_PATH } from './constants';
import { getDashboards, getMenus } from './services/app.service';
import { adminRouteData, publicRouteData } from './routes/routes';

export const initApp = async (
  unixId,
  { menus: { set }, user, dashboards: { set: setdashboards } },
) => {
  if (unixId) {
    const appMenus = [...publicRouteData];
    const {
      menus: menusResponse,
      error: menuError,
      message: menuMessage,
    } = await getMenus();

    if (menuError) {
      toast.error(menuMessage);
      set(appMenus);
      return appMenus;
    }

    const menusData = menusResponse?.[0]?.CATEGORIES || [];
    const sortedMenuData = menusData
      .filter((m) => !!m.CATEGORY_SLUG)
      .sort((a, b) => a.UX_ORDER - b.UX_ORDER);

    appMenus.push(...sortedMenuData);
    if (
      user?.role.toUpperCase() === 'ADMIN' ||
      user?.role.toUpperCase() === 'SYSADMIN'
    ) {
      appMenus.push(...adminRouteData);
    }
    set(appMenus);
    const {
      error,
      message,
      dashboards: dashboardsData,
    } = await getDashboards();

    if (error) {
      toast.error(message);
      return;
    }
    const sortedDashboards = dashboardsData.sort(
      (a, b) => a.UX_ORDER - b.UX_ORDER,
    );
    setdashboards(sortedDashboards);
    return menusData;
  }
};

export const createErrorResponse = (e) => {
  return {
    error: true,
    success: false,
    message:
      e?.data?.message || e?.response?.data?.message || 'Something went wrong!',
  };
};

export const createSuccessResponse = (response, as = 'data') => {
  return {
    error: false,
    success: true,
    message: response.data.message,
    [as]: response.data.data,
  };
};

export const createBreadcrumbFromPath = (pathname) => {
  const paths = pathname.split(APP_PATH).at(-1).split('/');

  const breadcrumb = [];
  paths.forEach((path) => {
    if (path.trim() !== '' && path.trim() !== APP_PATH) {
      breadcrumb.push(path);
    }
  });
  return breadcrumb;
};

export const formatDate = (date) => {
  if (!date) return '';

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  return new Date(date).toLocaleString('en-US', options);
};

export const getUnixId = () => {
  try {
    const jwt = localStorage.getItem('token');
    const claimObj = JSON.parse(atob(jwt.split('.')[1]));
    return claimObj?.unixId;
  } catch (e) {
    return null;
  }
};
