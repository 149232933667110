import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import TitleBar from '../../../components/ui/titlebar/TitleBar';
import ManageCategoriesPage from '../manage-categories/ManageCategoriesPage';
import ManageReportsPage from '../manage-reports/ManageReportsPage';
import ManageUsersPage from '../manage-users/ManageUsersPage';
import ManageRolesPage from '../manage-roles/ManageRolesPage';
import Icon from '../../../components/ui/icon/Icon';

export default function ManageSettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the "tab" query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get('tab') || 'categories'; // Default to 'categories'

  const [activeKey, setActiveKey] = useState(defaultTab);

  const adminModules = [
    {
      id: 'categories',
      label: 'Categories',
      element: <ManageCategoriesPage />,
      icon: 'dashboard',
    },
    {
      id: 'reports',
      label: 'Reports',
      element: <ManageReportsPage />,
      icon: 'reports',
    },
    {
      id: 'roles',
      label: 'Roles',
      element: <ManageRolesPage />,
      icon: 'user_group',
    },
    {
      id: 'users',
      label: 'Users',
      element: <ManageUsersPage />,
      icon: 'user',
    },
  ];

  // Update the query parameter when the active tab changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (activeKey !== params.get('tab')) {
      params.set('tab', activeKey);
      navigate({ search: params.toString() }, { replace: true }); // Update the query param without a full page reload
    }
  }, [activeKey, navigate, location.search]);

  return (
    <div className='p-4 admin-container'>
      <TitleBar
        title='Settings'
        icon='settings_black'
      />
      <div className='custom-tabs-container'>
        <Tabs
          id='custom-tabs'
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
          className='custom-tabs'>
          {adminModules.map((module) => (
            <Tab
              key={module.id}
              eventKey={module.id}
              title={
                <span>
                  <Icon
                    name={module.icon}
                    color='black'
                  />
                  {module.label}
                </span>
              }>
              {activeKey === module.id && (
                <div className='tab-content'>{module.element}</div>
              )}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
