// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CadahhM5lWWtAvQRwHzr{border:0;background:rgba(0,0,0,0);color:var(--color-tertiary-blue);display:flex;align-items:center;font:normal normal normal 14px/16px Gene Sans;letter-spacing:0px;color:var(--color-tertiary-blue);text-transform:capitalize}`, "",{"version":3,"sources":["webpack://./src/components/ui/button/LinkButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,wBAAA,CACA,gCAAA,CACA,YAAA,CACA,kBAAA,CACA,6CAAA,CACA,kBAAA,CACA,gCAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link-button": `CadahhM5lWWtAvQRwHzr`
};
export default ___CSS_LOADER_EXPORT___;
