import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  loading: false,
  loaded: false,
};

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setFavorites: (state, action) => {
      state.list = [...new Set(action.payload)];
      state.loading = false;
      state.loaded = true;
    },
    addFavorite: (state, action) => {
      state.list = [...new Set([...state.list, action.payload])];
    },
    deleteFavorite: (state, action) => {
      state.list = state.list.filter(
        (item) => item.dashboard_id !== action.payload.id,
      );
    },
    setFavoriteLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setFavorites, addFavorite, deleteFavorite, setFavoriteLoading } =
  favoritesSlice.actions;
export default favoritesSlice.reducer;
