import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import IconSelector from '../../../components/icon-selector/IconSelector';
import {
  createCategory,
  updateCategory,
} from '../../../services/manage-category.service';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useApp } from '../../../hooks/useApp';

export default function CreateCategoryForm({
  categories,
  setCategories,
  fetchCategories,
  data = {
    CATEGORY_ICON: 'medical',
  },
  actionType = 'create',
  handleClose,
}) {
  const { user } = useApp();
  const [categoryData, setCategoryData] = useState(data);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CATEGORY_NAME: categoryData?.CATEGORY_NAME || '',
      UX_ORDER: categoryData?.UX_ORDER || '',
      CATEGORY_ICON: categoryData?.CATEGORY_ICON || 'medical',
      UPDATED_BY: user.unixId,
    },
    mode: 'onBlur',
  });

  const onChangeHandler = (value, type) => {
    setCategoryData((prev) => ({ ...prev, [type]: value }));
    setValue(type, value);
  };

  const submitHandler = async (data) => {
    const actions = {
      create: createCategory,
      edit: updateCategory,
    };

    if (!data.CATEGORY_ICON) data.CATEGORY_ICON = 'medical';
    data.UPDATED_BY = user.unixId;

    try {
      if (actionType === 'edit') {
        data.ID = categoryData.ID;
      }

      const {
        error,
        message,
        category: responseData,
      } = await actions[actionType](data);

      if (error) {
        toast.error(message);
        return;
      }

      if (actionType === 'create') {
        fetchCategories();
      } else {
        let temp = [...categories];
        if (!data.ID) return toast.error('No Category ID was found!');
        let index = temp.findIndex((t) => t.ID === data.ID);

        if (index !== -1) {
          temp[index] = {
            ...temp[index],
            ...responseData,
          };
        }
        setCategories(temp);
      }

      toast.success(message);
      handleClose();
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Form.Group
        className='mb-3'
        controlId='formBasicEmail'>
        <Form.Label>Category Name</Form.Label>
        <Form.Control
          type='text'
          placeholder='Enter Category Name'
          {...register('CATEGORY_NAME', {
            required: 'Category Name is required',
          })}
        />
        {errors.CATEGORY_NAME && (
          <Form.Text className='text-danger'>
            {errors.CATEGORY_NAME.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group
        className='mb-3'
        controlId='formOrder'>
        <Form.Label>Order</Form.Label>
        <Form.Control
          type='number'
          placeholder='Enter Order'
          {...register('UX_ORDER', {
            required: 'Order is required',
          })}
        />
        {errors.UX_ORDER && (
          <Form.Text className='text-danger'>
            {errors.UX_ORDER.message}
          </Form.Text>
        )}
      </Form.Group>

      <IconSelector
        onChange={(value) => onChangeHandler(value, 'CATEGORY_ICON')}
        selectedIcon={categoryData?.CATEGORY_ICON}
      />

      <Button
        className='mt-4'
        type='submit'>
        Submit
      </Button>
    </Form>
  );
}

CreateCategoryForm.propTypes = {
  categories: PropTypes.array.isRequired,
  setCategories: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  data: PropTypes.shape({
    CATEGORY_ICON: PropTypes.string,
    CATEGORY_NAME: PropTypes.string,
    category_description: PropTypes.string,
    ID: PropTypes.number,
    UPDATED_BY: PropTypes.string,
  }),
  actionType: PropTypes.oneOf(['create', 'edit']),
  handleClose: PropTypes.func.isRequired,
};

CreateCategoryForm.defaultProps = {
  data: {
    CATEGORY_ICON: 'medical',
    CATEGORY_NAME: '',
    category_description: '',
    ID: null,
  },
  actionType: 'create',
};
