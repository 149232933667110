import { useEffect } from 'react';
import { addToRecentView } from '../../services/app.service';
import { toast } from 'react-toastify';

export const useRecentTracking = ({ reportId }) => {
  useEffect(() => {
    (async () => {
      if (!reportId) return;
      try {
        const { error, message } = await addToRecentView(reportId);
        if (error) {
          toast.error(message);
          return;
        }
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [reportId]);
};
