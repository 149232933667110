// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WUqkNR3u5DEBY8m4ElMp{text-decoration:none;display:flex;margin-right:10px;margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/components/help/Help.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,YAAA,CACA,iBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpLink": `WUqkNR3u5DEBY8m4ElMp`
};
export default ___CSS_LOADER_EXPORT___;
