export const APP_URL = '/analyticshub/digital-insight/oci';
export const BASE_URL = '/analyticshub/digital-insight';
export const APP_PATH = '/analyticshub/digital-insight/oci/';

export const HELP_URL =
  'https://docs.google.com/presentation/d/1Dcu2ZtjrdgseN9IosiSju7YdDJrKi0ejaj4riOXDYF8/edit?usp=sharing';

export const icons = [
  'feedback',
  'help',
  'home',
  'medical',
  'performance',
  'web',
  'media',
  'email',
  'guide',
  'archive',
  'apps',
  'favorite',
  'favoriteActive',
  'menu-collapse',
  'menu-expand',
  'user_avatar',
  'add',
  'delete',
  'edit',
  'externalLink',
  'info',
  'sort',
  'settings',
  'search',
];
