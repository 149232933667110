import api from '../../api';
import { createErrorResponse, createSuccessResponse } from '../utils';

export async function getAllRoles() {
  try {
    const roleResponse = await api.get(`/roles/all`);
    if (roleResponse.data.status === 'error') {
      return createErrorResponse(roleResponse);
    }
    return createSuccessResponse(roleResponse, 'roles');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function getRoles({ page = 1, pageSize = 10 }) {
  try {
    const roleResponse = await api.get(
      `/roles?page=${page}&pageSize=${pageSize}`,
    );
    if (roleResponse.data.status === 'error') {
      return createErrorResponse(roleResponse);
    }
    return createSuccessResponse(roleResponse, 'roles');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function createRole(data) {
  try {
    const roleResponse = await api.post(`/roles`, data);
    if (roleResponse.data.status === 'error') {
      return createErrorResponse(roleResponse);
    }
    return createSuccessResponse(roleResponse, 'role');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function updateRole(data) {
  try {
    const roleResponse = await api.put(`/roles`, data);
    if (roleResponse.data.status === 'error') {
      return createErrorResponse(roleResponse);
    }
    return createSuccessResponse(roleResponse, 'role');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function deleteRole(id) {
  try {
    const roleResponse = await api.delete(`/roles/${id}`);
    if (roleResponse.data.status === 'error') {
      return createErrorResponse(roleResponse);
    }
    return createSuccessResponse(roleResponse, 'role');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}
