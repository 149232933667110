import React from 'react';
import { Table } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import Swal from 'sweetalert2';
import { deleteCategory } from '../../../services/manage-category.service';
import { toast } from 'react-toastify';
import Icon from '../../../components/ui/icon/Icon';
import PropTypes from 'prop-types';
import { formatDate } from '../../../utils';

const deleteCategoryHandler = async (categoryId, fetchCategories) => {
  try {
    const { error, message } = await deleteCategory(categoryId);
    if (error) {
      toast.error(message);
      return;
    }

    fetchCategories();

    toast.success(message);
  } catch (error) {
    toast.error(error.message);
  }
};

const confirmDelete = (categoryId, fetchCategories) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(async (result) => {
    if (result.isConfirmed) {
      await deleteCategoryHandler(categoryId, fetchCategories);
    }
  });
};

export default function CategoryList({
  categories,
  fetchCategories,
  setEditData,
}) {
  return (
    <Table
      hover
      className='mt-4'>
      <thead>
        <tr>
          <th className='text-nowrap'>Actions</th>
          <th className='text-nowrap'>Category Icon</th>
          <th className='text-nowrap'>Category Name</th>
          <th className='text-nowrap'>Order</th>
          <th className='text-nowrap'>Created At</th>
          <th className='text-nowrap'>Updated At</th>
          <th className='text-nowrap'>Updated By</th>
        </tr>
      </thead>
      <tbody>
        {categories.length === 0 && (
          <tr>
            <td
              colSpan={8}
              className='text-center'>
              No Categories found
            </td>
          </tr>
        )}
        {categories?.map((category) => (
          <tr key={category.ID}>
            <td>
              <div className='d-flex gap-1'>
                <Button
                  className='me-1'
                  onClick={() => setEditData(category)}
                  title='Edit category'
                  variant='icon'>
                  <i className='icon icon__edit me-0'></i>
                </Button>
                <Button
                  onClick={() => confirmDelete(category.ID, fetchCategories)}
                  variant='icon'
                  title='Delete category'>
                  <i className='icon icon__delete me-0'></i>
                </Button>
              </div>
            </td>
            <td>
              <Icon
                name={category.CATEGORY_ICON}
                color='black'
              />
            </td>
            <td>{category.CATEGORY_NAME}</td>
            <td>{category.UX_ORDER}</td>
            <td>{formatDate(category.CREATED_DATE)}</td>
            <td>{formatDate(category.UPDATED_DATE)}</td>
            <td>{category.UPDATED_BY}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      CATEGORY_ICON: PropTypes.string.isRequired,
      CATEGORY_NAME: PropTypes.string.isRequired,
      CREATED_DATE: PropTypes.string,
      UPDATED_BY: PropTypes.string.isRequired,
      UPDATED_DATE: PropTypes.string,
    }),
  ).isRequired,
  setEditData: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
};
