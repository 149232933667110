import { useEffect, useState } from 'react';
import { getUserDetails } from '../../services/app.service';
import store from '../../store/store';
import { getUnixId } from '../../utils';

export const useFetchUser = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const unixId = getUnixId();
        if (!unixId) {
          setIsAuthorized(false);
          return;
        }
        const { error: userError, user: userData } = await getUserDetails();
        const isUser = !userError && userData && userData?.ROLE_CODE;
        if (!isUser) {
          setIsAuthorized(false);
          return;
        }
        const user = {
          unixId,
          role: userData?.ROLE_CODE,
          email: userData?.EMAIL,
          id: userData?.UNIX_ID,
          name: userData?.NAME,
          loaded: true,
        };
        store.dispatch({
          type: 'user/setUser',
          payload: user,
        });
        setIsAuthorized(true);
      } catch (e) {
        console.error('Error fetching user:', e);
        setIsAuthorized(false);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return { loading, isAuthorized };
};
