import api from '../../api';
import { createErrorResponse, createSuccessResponse } from '../utils';
export async function getReports({ page = 1, pageSize = 10 }) {
  try {
    const reportResponse = await api.get(
      `/reports?page=${page}&pageSize=${pageSize}`,
    );
    if (reportResponse.data.status === 'error') {
      return createErrorResponse(reportResponse);
    }
    return createSuccessResponse(reportResponse, 'reports');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function getAllCategories() {
  try {
    const reportResponse = await api.get(`/reports/categories`);
    if (reportResponse.data.status === 'error') {
      return createErrorResponse(reportResponse);
    }
    return createSuccessResponse(reportResponse, 'categories');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function getAllReports(roleId) {
  try {
    const reportResponse = await api.get(`/roles/reports/${roleId}`);
    if (reportResponse.data.status === 'error') {
      return createErrorResponse(reportResponse);
    }
    return createSuccessResponse(reportResponse, 'reports');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function editRoleMappings(roleId, updatedMappings, updatedBy) {
  try {
    const reportResponse = await api.post(`/roles/assignReports`, {
      ROLE_ID: roleId,
      ADD_REPORTS_ID: updatedMappings.add_mappings,
      REMOVE_REPORTS_ID: updatedMappings.remove_mappings,
      REPORT_VISIBILITY_FLAG: 'On',
      UPDATED_BY: updatedBy,
    });
    if (reportResponse.data.status === 'error') {
      return createErrorResponse(reportResponse);
    }
    return createSuccessResponse(reportResponse, 'report');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function createReport(data) {
  try {
    const reportResponse = await api.post(`/reports`, {
      ...data,
    });
    if (reportResponse.data.status === 'error') {
      return createErrorResponse(reportResponse);
    }
    return createSuccessResponse(reportResponse, 'report');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function updateReport(data) {
  try {
    const reportResponse = await api.put(`/reports`, data);
    if (reportResponse.data.status === 'error') {
      return createErrorResponse(reportResponse);
    }
    return createSuccessResponse(reportResponse, 'report');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function deleteReport(id) {
  try {
    const reportResponse = await api.delete(`/reports/${id}`);
    if (reportResponse.data.status === 'error') {
      return createErrorResponse(reportResponse);
    }
    return createSuccessResponse(reportResponse, 'report');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}
