import React from 'react';
import { APP_URL } from '../constants';
import { Route } from 'react-router-dom';
import { useApp } from '../hooks/useApp';
import ManageSettingsPage from '../pages/admin/manage-settings/ManageSettingsPage';

export default function AdminRoutes() {
  const { user } = useApp();
  const adminRoutes = [{ path: 'settings', element: <ManageSettingsPage /> }];
  return (
    (user?.role.toUpperCase() === 'ADMIN' ||
      user?.role.toUpperCase() === 'SYSADMIN') &&
    adminRoutes.map((a) => {
      return (
        <Route
          key={a.path}
          path={`${APP_URL}/${a.path}`}
          element={a.element}>
          {a?.children?.map((s) => (
            <Route
              key={`${a.path}${s.path}`}
              path={`${APP_URL}/${a.path}/${s.path}`}
              element={s.element}
            />
          ))}
        </Route>
      );
    })
  );
}
