// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GhHYn5YGDRMACnb0XLCb{height:calc(100vh - 48px)}.GhHYn5YGDRMACnb0XLCb .ViQizZGGOFY6aVFJWnH2{font-size:18px;height:auto;line-height:normal}.GhHYn5YGDRMACnb0XLCb .ar7F0tlxwcvD8eOc9EGX{width:100px;margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/not-found/NotFound.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,4CACE,cAAA,CACA,WAAA,CACA,kBAAA,CAEF,4CACE,WAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": `GhHYn5YGDRMACnb0XLCb`,
	"go-home-btn": `ViQizZGGOFY6aVFJWnH2`,
	"notFoundImage": `ar7F0tlxwcvD8eOc9EGX`
};
export default ___CSS_LOADER_EXPORT___;
