import React, { useState } from 'react';
import TitleBar from '../ui/titlebar/TitleBar';
import AddToFavorites from './AddToFavorites';
import FavoritesList from './FavoritesList';

export default function Favorites() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <TitleBar
        title='Favorites'
        icon='edit_blue'
        dir='right'
        onIconClick={handleShow}
      />
      <FavoritesList />
      {show && (
        <AddToFavorites
          show={show}
          onClose={handleClose}
          onShow={handleShow}
        />
      )}
    </>
  );
}
