// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aidN91rCH5Aqe0siRuhT{margin-left:20px;position:relative;bottom:-1px}.aidN91rCH5Aqe0siRuhT ol{margin-bottom:0;align-items:baseline}.aidN91rCH5Aqe0siRuhT ol li{font:normal normal normal 14px/22px Gene Sans;letter-spacing:0px;color:var(--color-white) !important;opacity:1}.aidN91rCH5Aqe0siRuhT ol li a{font:normal normal normal 14px/22px Gene Sans;letter-spacing:0px;color:var(--color-white);opacity:1;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/components/ui/breadcrumb/Breadcrumb.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,iBAAA,CACA,WAAA,CACA,yBACE,eAAA,CACA,oBAAA,CACA,4BACE,6CAAA,CACA,kBAAA,CACA,mCAAA,CACA,SAAA,CACA,8BACE,6CAAA,CACA,kBAAA,CACA,wBAAA,CACA,SAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `aidN91rCH5Aqe0siRuhT`
};
export default ___CSS_LOADER_EXPORT___;
