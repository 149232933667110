/* eslint-disable no-unused-vars */
import React from 'react';
import tableau from 'tableau-api';
import { useRecentTracking } from '../../hooks/tracking/useRecentTracking';
import PropTypes from 'prop-types';
import Iframe from '../../components/ui/iframe/Iframe';

const TableauDashboard = ({ id, url }) => {
  useRecentTracking({ reportId: id });
  return <Iframe src={url}></Iframe>;
};

TableauDashboard.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

export default TableauDashboard;
