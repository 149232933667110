import React from 'react';
import { Modal as BSModal } from 'react-bootstrap';
import Button from '../button/Button';
import LinkButton from '../button/LinkButton';
import PropTypes from 'prop-types';
export default function Modal({
  show,
  size = 'lg',
  onHide = () => {},
  title,
  body,
  footer,
  hideFooter = false,
  onSave = () => {},
  disableSave = false,
  // onClose = () => {},
}) {
  return (
    <BSModal
      size={size}
      show={show}
      onHide={onHide}
      centered>
      <BSModal.Header closeButton>
        <BSModal.Title className='title font__bold'>{title}</BSModal.Title>
      </BSModal.Header>
      <hr className='mx-3 my-0' />

      <BSModal.Body>{body}</BSModal.Body>

      {!hideFooter && (
        <BSModal.Footer>
          {footer ? (
            footer
          ) : (
            <>
              <LinkButton
                onClick={onHide}
                variant=''>
                Close
              </LinkButton>
              <Button
                disabled={disableSave}
                onClick={() => {
                  onSave();
                  onHide();
                }}>
                Save changes
              </Button>
            </>
          )}
        </BSModal.Footer>
      )}
    </BSModal>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node,
  disableSave: PropTypes.bool,
  hideFooter: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
};

Modal.defaultProps = {
  onHide: () => {},
  footer: null,
  hideFooter: false,
  onSave: () => {},
  onClose: () => {},
};
