import React from 'react';
import classes from './Help.module.scss';
import { HELP_URL } from '../../constants';
export default function Help() {
  return (
    <a
      href={HELP_URL}
      target='__blank'
      className={classes.helpLink}>
      <i className='icon icon__help me-0'></i>
    </a>
  );
}
