import React, { useEffect, useState } from 'react';
import TitleBar from '../../../components/ui/titlebar/TitleBar';
import Button from '../../../components/ui/button/Button';
import Modal from '../../../components/ui/modal/Modal';
import CreateReportForm from './CreateReportForm';
import ReportList from './ReportList';
import {
  getAllCategories,
  getReports,
} from '../../../services/manage-report.service';
import { toast } from 'react-toastify';
import Loading from '../../../components/ui/loading/Loading';
import Pagination from '../../../components/ui/pagination/Pagination';

const ManageReportsPage = () => {
  const [categories, setCategories] = useState([]);
  const [editData, setEditData] = useState(null);
  const [show, setShow] = useState(false);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  // Single object for pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  });

  const handlePageChange = async (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const fetchReports = async () => {
    try {
      setLoading(true);
      const {
        error,
        message,
        reports: reportsData,
      } = await getReports({
        page: pagination.currentPage,
        pageSize: pagination.pageSize,
      });

      if (error) {
        toast.error(message);
        return;
      }

      setPagination((prev) => ({
        ...prev,
        totalPages: reportsData.totalPages,
      }));

      setReports(reportsData.reports);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [pagination.currentPage, pagination.pageSize]);

  const handleClose = () => {
    setShow(false);
    setEditData(null);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (editData) {
      handleShow();
    }
  }, [editData]);

  useEffect(() => {
    // Fetch dashboard types from API
    const fetchCategories = async () => {
      try {
        const {
          error,
          message,
          categories: categoriesData,
        } = await getAllCategories();
        if (error) {
          toast.error(message);
          return;
        }
        setCategories(categoriesData);
      } catch (error) {
        toast.error('Failed to fetch all categories.');
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className='py-4 admin-container'>
      {/* <TitleBar
        title='Manage Reports'
        icon='reports_black'
        buttons={<Button onClick={handleShow}>Add New Report</Button>}
      /> */}
      <Button onClick={handleShow}>Add New Report</Button>
      <Modal
        show={show}
        onHide={handleClose}
        title={editData ? 'Update Report' : 'Add New Report'}
        hideFooter
        body={
          <CreateReportForm
            reports={reports}
            setReports={setReports}
            data={editData}
            actionType={editData ? 'edit' : 'create'}
            handleClose={handleClose}
            categories={categories}
            fetchReports={fetchReports}
          />
        }
      />
      <Loading state={loading} />
      <ReportList
        reports={reports}
        setEditData={setEditData}
        fetchReports={fetchReports}
      />
      <Pagination
        currentPage={pagination.currentPage}
        onPageChange={handlePageChange}
        totalPages={pagination.totalPages}
      />
    </div>
  );
};

export default ManageReportsPage;
