import React, { useEffect, useState } from 'react';
import classes from './Searchbar.module.scss';
import { Form, ListGroup } from 'react-bootstrap';
import { useApp } from '../../hooks/useApp';
import Loading from '../ui/loading/Loading';
import NoData from '../ui/no-data/NoData';
import { addToRecentView, getDashboards } from '../../services/app.service';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { APP_URL } from '../../constants';
import PropTypes from 'prop-types';
import Icon from '../ui/icon/Icon';
export default function Searchbar({ show, onClose }) {
  const navigate = useNavigate();
  const { dashboards, user } = useApp();
  const [list, setList] = useState(dashboards.list);
  const [dashboardGroup, setDashboardGroup] = useState(
    _.groupBy(dashboards.list, 'CATEGORY_NAME'),
  );

  const handleChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    const temp = dashboards.list.filter((l) =>
      l.REPORT_NAME.toUpperCase().includes(value.toUpperCase()),
    );
    setList(temp);
  };

  const trackHandler = (report) => {
    if (report.IS_EXTERNAL === 'Y') {
      addToRecentView(report.REPORT_ID);
      window.open(report.REPORT_URL, '_blank', 'noopener,noreferrer');
    } else {
      navigate(`${APP_URL}/${report.CATEGORY_SLUG}/${report.REPORT_SLUG}`);
    }
    onClose();
  };

  useEffect(() => {
    (async () => {
      try {
        if (!show || dashboards.list.length > 0) return;
        dashboards.setLoading(true);
        const {
          error,
          message,
          dashboards: dashboardsData,
        } = await getDashboards();
        dashboards.setLoading(false);
        if (error) {
          toast.error(message);
          return;
        }

        dashboards.set(dashboardsData);
        setList(dashboardsData);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [user.unixId]);

  useEffect(() => {
    setDashboardGroup(_.groupBy(dashboards.list, 'CATEGORY_NAME'));
  }, [dashboards.list]);

  useEffect(() => {
    setDashboardGroup(_.groupBy(list, 'CATEGORY_NAME'));
  }, [list]);

  useEffect(() => {
    setList(dashboards.list);
    setDashboardGroup(_.groupBy(dashboards.list, 'CATEGORY_NAME'));
  }, [show]);

  return (
    show && (
      <div
        id='search-modal'
        className={classes.searchOverlay}
        onClick={(e) => {
          if (e.target.id === 'search-modal') {
            onClose();
          }
        }}>
        <Form>
          {!(!dashboards.loading && dashboards.list.length === 0) && (
            <Form.Group
              className='mb-3'
              controlId='formBasicEmail'>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={handleChange}
              />
            </Form.Group>
          )}

          <div className={classes.favList}>
            <ListGroup variant='flush'>
              <Loading state={dashboards.loading} />
              {!dashboards.loading && dashboards.list.length === 0 && (
                <NoData
                  title='No Assigned dashboards!'
                  subTitle='There are no dashboards assigned to you yet. Contact your administrator for more information'
                />
              )}
              {!dashboards.loading &&
                list.length === 0 &&
                dashboards.list.length !== 0 && (
                  <NoData
                    title='No results found!'
                    subTitle='There are no results found for the search query'
                  />
                )}
              {Object.keys(dashboardGroup).map((f, i) => {
                return (
                  <React.Fragment key={i}>
                    <h3 className={classes.dashboardGroup}>{f}</h3>
                    <div className={classes.dashboardList}>
                      {dashboardGroup[f].map((d) => {
                        return (
                          <ListGroup.Item
                            className='ps-0'
                            key={d.REPORT_ID}
                            onClick={() => trackHandler(d)}>
                            {d.REPORT_NAME}
                            {d.IS_EXTERNAL === 'Y' && (
                              <Icon
                                name='externalLink'
                                color='blue'
                                className='ms-2'
                              />
                            )}
                          </ListGroup.Item>
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </ListGroup>
          </div>
        </Form>
      </div>
    )
  );
}

Searchbar.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
