import api from '../../api';
import { createErrorResponse, createSuccessResponse, getUnixId } from '../utils';

export async function getUserDetails() {
  try {
    const userResponse = await api.get(`/users/${getUnixId()}`);
    if (userResponse.data.status === 'error') {
      return createErrorResponse(userResponse);
    }
    return createSuccessResponse(userResponse, 'user');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function getMenus() {
  try {
    const menuResponse = await api.get(`/users/${getUnixId()}/reports`);
    if (menuResponse.data.status === 'error') {
      return createErrorResponse(menuResponse);
    }
    return createSuccessResponse(menuResponse, 'menus');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function getRecents() {
  try {
    const response = await api.get(`/users/${getUnixId()}/recently-viewed`);
    if (response.data.status === 'error') {
      return createErrorResponse(response);
    }
    return createSuccessResponse(response, 'recents');
  } catch (e) {
    console.log(e);
    return createErrorResponse(e);
  }
}

export async function getFavorites() {
  try {
    const response = await api.get(`/users/${getUnixId()}/favorites`);
    if (response.data.status === 'error') {
      return createErrorResponse(response);
    }
    return createSuccessResponse(response, 'favorites');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function getDashboards() {
  try {
    const response = await api.get(`/users/${getUnixId()}/favorites/all`);
    if (response.data.status === 'error') {
      return createErrorResponse(response);
    }
    return createSuccessResponse(response, 'dashboards');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function addToFavorites(reportId) {
  try {
    const response = await api.post(`/favorites`, {
      UNIX_ID: getUnixId(),
      REPORT_ID: reportId,
      UPDATED_BY: getUnixId(),
    });
    if (response.data.status === 'error') {
      return createErrorResponse(response);
    }
    return createSuccessResponse(response, 'favorites');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function removeFromFavorites(reportId) {
  try {
    const response = await api.delete(
      `/users/${getUnixId()}/favorites?REPORT_ID=${reportId}`,
    );
    if (response.data.status === 'error') {
      return createErrorResponse(response);
    }
    return createSuccessResponse(response, 'favorites');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function editFavorites(data) {
  try {
    const userId = getUnixId();
    const response = await api.post(`/favorites/bulk`, {
      ...data,
      userId,
    });
    if (response.data.status === 'error') {
      return createErrorResponse(response);
    }
    return createSuccessResponse(response, 'favorites');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function addToRecentView(reportId) {
  try {
    const recentResponse = await api.post(`/recently-viewed`, {
      REPORT_ID: reportId,
      UNIX_ID: getUnixId(),
      UPDATED_BY: getUnixId(),
    });
    if (recentResponse.data.status === 'error') {
      return createErrorResponse(recentResponse);
    }
    return createSuccessResponse(recentResponse, 'recent');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}
