import PropTypes from 'prop-types';
import React from 'react';
export default function NavSubMenu({ item, collapsed }) {
  return (
    <>
      <i className={`icon icon__${item.CATEGORY_ICON} nav-link-icon`}></i>
      {!collapsed && item.CATEGORY_NAME}
    </>
  );
}

NavSubMenu.propTypes = {
  item: PropTypes.shape({
    CATEGORY_ICON: PropTypes.string.isRequired,
    CATEGORY_NAME: PropTypes.string.isRequired,
  }).isRequired,
  collapsed: PropTypes.bool.isRequired,
};
