import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  loading: false,
};

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setDashboards: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    addDashboard: (state, action) => {
      state.list.push(action.payload);
    },
    deleteDashboard: (state, action) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },
    setDashboardLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setDashboards,
  addDashboard,
  deleteDashboard,
  setDashboardLoading,
} = dashboardsSlice.actions;
export default dashboardsSlice.reducer;
