import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { APP_URL } from '../constants';
import TableauDashboard from '../pages/tableau-dashboard/TableauDashboard';
import QlikDashboard from '../pages/qlik-dashboard/QlikDashboard';
import AppLandingPage from '../pages/app-landing/AppLanding';

export default function DynamicRoutes({ routes }) {
  return (
    <>
      {routes.map((category) => {
        if (!category.CATEGORY_NAME) return null;

        return (
          <React.Fragment key={category.CATEGORY_SLUG}>
            {/* Route for the Category Landing Page */}
            <Route
              path={`${APP_URL}/${category.CATEGORY_SLUG}`}
              element={
                <AppLandingPage
                  name={category.CATEGORY_NAME}
                  path={category.CATEGORY_SLUG}
                  icon={category.CATEGORY_ICON}
                  dashboards={category.REPORTS}
                />
              }
            />

            {/* Routes for Individual Reports */}
            {category.REPORTS.map((report) => (
              <Route
                key={`${category.CATEGORY_SLUG}-${report.REPORT_SLUG}`}
                path={`${APP_URL}/${category.CATEGORY_SLUG}/${report.REPORT_SLUG}`}
                element={
                  report.APP_SOURCE === 'TABLEAU' ? (
                    <TableauDashboard
                      id={report.ID}
                      url={report.REPORT_URL}
                    />
                  ) : (
                    <QlikDashboard
                      id={report.ID}
                      url={report.REPORT_URL}
                    />
                  )
                }
              />
            ))}
          </React.Fragment>
        );
      })}
    </>
  );
}

DynamicRoutes.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      CATEGORY_NAME: PropTypes.string.isRequired,
      CATEGORY_SLUG: PropTypes.string.isRequired,
      CATEGORY_ICON: PropTypes.string,
      UX_ORDER: PropTypes.number,
      REPORTS: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.number.isRequired,
          REPORT_NAME: PropTypes.string.isRequired,
          REPORT_SLUG: PropTypes.string.isRequired,
          REPORT_URL: PropTypes.string.isRequired,
          IS_EXTERNAL: PropTypes.string,
          APP_SOURCE: PropTypes.oneOf(['TABLEAU', 'QLIK SENSE']).isRequired,
          IS_ACTIVE: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
};
