// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zvpKbPPAKXdbL_tEI1jY{height:48px;width:calc(100% - 59px);background:rgba(0,0,0,0) var(--gradient-primary) 0% 0% no-repeat padding-box;display:flex;align-items:center;color:var(--color-white);padding-left:24px;position:absolute;top:0;z-index:3;margin-left:59px}.oKLkPL61pRWgpQexRYD7{display:flex;justify-content:flex-end;align-items:center}.FoR5TBUC3KpwaI4Xa4vb{display:flex;align-items:center;text-decoration:none;color:#fff}.CfM8O0eo4Id5kgcz8VgN{position:relative;top:2px}`, "",{"version":3,"sources":["webpack://./src/components/layout/header/Header.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,uBAAA,CACA,4EAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA,CACA,KAAA,CACA,SAAA,CACA,gBAAA,CAEF,sBACE,YAAA,CACA,wBAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,OAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gne-header": `zvpKbPPAKXdbL_tEI1jY`,
	"header-cta": `oKLkPL61pRWgpQexRYD7`,
	"content-wrap": `FoR5TBUC3KpwaI4Xa4vb`,
	"app-name": `CfM8O0eo4Id5kgcz8VgN`
};
export default ___CSS_LOADER_EXPORT___;
