import React from 'react';
import LinkButton from '../ui/button/LinkButton';
import classes from './Search.module.scss';
import PropTypes from 'prop-types';
export default function Search({ setShow }) {
  return (
    <div>
      <LinkButton
        className={`links ${classes.links} ${classes.cta}`}
        to='#'
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}>
        <i className='icon icon__search'></i> Search
      </LinkButton>
    </div>
  );
}

Search.propTypes = {
  setShow: PropTypes.func.isRequired,
};
