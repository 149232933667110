import React from 'react';
import classes from './NotFound.module.scss';
import notFoundImg from '../../assets/images/app/not-found.png';
import Button from '../../components/ui/button/Button';
import { Link } from 'react-router-dom';
export default function NotFound() {
  return (
    <div
      className={`custom-bg text-dark text-center d-flex flex-column align-items-center justify-content-center px-2 ${classes.notFound}`}>
      <img
        src={notFoundImg}
        className={classes.notFoundImage}
      />
      <h1 className='display-4 fw-bold'>404</h1>
      <p className='fs-2 fw-medium mt-2'>Oops! Page not found</p>
      <p className='mt-2 mb-4'>
        The page you&apos;re looking for doesn&apos;t exist or has been moved.
      </p>
      <Link to='/oci/home'>
        <Button className={`px-4 py-2 font-large ${classes['go-home-btn']}`}>
          Go Home
        </Button>
      </Link>
    </div>
  );
}
