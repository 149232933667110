import React, { useEffect, useState } from 'react';
import { useApp } from '../../hooks/useApp';
import classes from './Bookmark.module.scss';
import {
  addToFavorites,
  removeFromFavorites,
} from '../../services/app.service';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

export default function Bookmark({
  id,
  size = 'md',
  icon = {
    default: 'favorite_blue',
    active: 'favoriteActive_blue',
  },
}) {
  const { dashboards } = useApp();

  const [isFav, setIsFav] = useState(
    dashboards.list.find((v) => {
      if (v.REPORT_ID === id && v.IS_FAVORITE === 'Yes') return true;
    }),
  );

  const [disable, setDisable] = useState(false);

  const handleAdd = async (e) => {
    e.stopPropagation();
    const { error, message } = await addToFavorites(id);
    if (error) {
      toast.error(message);
    }
    return { error, message };
  };

  const handleRemove = async (e) => {
    e.stopPropagation();
    const { error, message } = await removeFromFavorites(id);
    if (error) {
      toast.error(message);
    }
    return { error, message };
  };

  const handleToggleClick = async (e, type = 'add') => {
    const actions = {
      add: handleAdd,
      remove: handleRemove,
    };
    setDisable(true);
    const { error } = await actions[type](e);
    setDisable(false);

    if (error) return;
    const tempList = structuredClone(dashboards.list);
    const dashboard = tempList.find((t) => t.REPORT_ID === id);
    dashboard.IS_FAVORITE = type === 'add' ? 'Yes' : 'No';
    dashboards.set(tempList);
    setIsFav(type === 'add' ? true : false);
  };

  useEffect(() => {
    setIsFav(
      dashboards.list.find((v) => {
        if (v.REPORT_ID === id && v.IS_FAVORITE === 'Yes') return true;
      }),
    );
  }, [dashboards.list]);

  return (
    <button
      className={classes.bookmarkBtn}
      disabled={disable}
      onClick={(e) => {
        handleToggleClick(e, isFav ? 'remove' : 'add');
      }}>
      {!isFav ? (
        <i
          className={`icon icon__${icon.default} ${size === 'md' ? '' : classes.lgIcon} nav-link-icon me-1`}></i>
      ) : (
        <i
          className={`icon icon__${icon.active} ${size === 'md' ? '' : classes.lgIcon} nav-link-icon me-1`}></i>
      )}
    </button>
  );
}

Bookmark.propTypes = {
  id: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['md', 'lg']),
  icon: PropTypes.shape({
    default: PropTypes.string,
    active: PropTypes.string,
  }),
};

Bookmark.defaultProps = {
  icon: {
    default: 'favorite_blue',
    active: 'favoriteActive_blue',
    size: 'md',
  },
};
