import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import {
  createReport,
  updateReport,
} from '../../../services/manage-report.service';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useApp } from '../../../hooks/useApp';

const APP_SOURCES = ['QLIK SENSE', 'TABLEAU'];

export default function CreateReportForm({
  reports,
  fetchReports,
  setReports,
  categories,
  data = {
    REPORT_NAME: '',
    REPORT_URL: '',
    IS_EXTERNAL: 'N',
    IS_ACTIVE: 'Y',
    APP_SOURCE: '',
  },
  actionType = 'create',
  handleClose,
}) {
  const { user } = useApp();
  const [reportData, setReportData] = useState(data || { IS_ACTIVE: 'Y' });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CATEGORY_ID: reportData?.CATEGORY_ID || '',
      REPORT_NAME: reportData?.REPORT_NAME || '',
      REPORT_URL: reportData?.REPORT_URL || '',
      IS_EXTERNAL: reportData?.IS_EXTERNAL || 'N',
      IS_ACTIVE: reportData?.IS_ACTIVE || 'Y',
      APP_SOURCE: reportData?.APP_SOURCE || '',
      UPDATED_BY: user.unixId,
    },
    mode: 'onBlur',
  });

  const onChangeHandler = (checked, type) => {
    const value = checked ? 'Y' : 'N';
    setReportData((prev) => ({ ...prev, [type]: value }));
    setValue(type, value);
    if (type === 'IS_EXTERNAL' && !checked) {
      setValue('APP_SOURCE', '');
      setReportData((prev) => ({ ...prev, APP_SOURCE: '' }));
    }
  };

  const submitHandler = async (formData) => {
    const actions = {
      create: createReport,
      edit: updateReport,
    };
    formData.UPDATED_BY = user.unixId;

    try {
      if (actionType === 'edit') {
        formData.ID = reportData.ID;
      }
      if (formData.IS_EXTERNAL === 'Y') {
        formData.APP_SOURCE = 'EXTERNAL';
      }
      const {
        error,
        message,
        report: responseData,
      } = await actions[actionType](formData);
      if (error) {
        toast.error(message);
        return;
      }

      if (actionType === 'create') {
        fetchReports();
      } else {
        let updatedReports = [];
        updatedReports = reports.map((report) =>
          report.ID === formData.ID ? { ...report, ...responseData } : report,
        );
        setReports(updatedReports);
      }

      toast.success(message);
      handleClose();
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    reset(reportData);
  }, [reportData, reset]);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Form.Group className='mb-3'>
        <Form.Label htmlFor='appSource'>Category</Form.Label>
        <Form.Select
          id='CATEGORY_ID'
          {...register('CATEGORY_ID', {
            required: 'Category is required',
          })}
          className={errors.CATEGORY_ID ? 'is-invalid' : ''}>
          <option value=''>Select a category</option>
          {categories.map((c) => (
            <option
              key={c.ID}
              value={c.ID}>
              {c.CATEGORY_NAME}
            </option>
          ))}
        </Form.Select>
        {errors.CATEGORY_ID && (
          <div className='invalid-feedback'>{errors.CATEGORY_ID.message}</div>
        )}
      </Form.Group>

      <Form.Group
        className='mb-3'
        controlId='formReportName'>
        <Form.Label>Report Name</Form.Label>
        <Form.Control
          type='text'
          placeholder='Enter Report Name'
          {...register('REPORT_NAME', { required: 'Report Name is required' })}
        />
        {errors.REPORT_NAME && (
          <Form.Text className='text-danger'>
            {errors.REPORT_NAME.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group
        className='mb-3'
        controlId='formReportURL'>
        <Form.Label>Report URL</Form.Label>
        <Form.Control
          type='text'
          placeholder='Enter Report URL'
          {...register('REPORT_URL', { required: 'Report URL is required' })}
        />
        {errors.REPORT_URL && (
          <Form.Text className='text-danger'>
            {errors.REPORT_URL.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group
        className='mb-3'
        controlId='formIsExternal'>
        <Form.Check
          type='checkbox'
          label='Is External'
          checked={reportData?.IS_EXTERNAL === 'Y'}
          onChange={(e) => onChangeHandler(e.target.checked, 'IS_EXTERNAL')}
        />
      </Form.Group>

      <Form.Group
        className='mb-3'
        controlId='formIsActive'>
        <Form.Check
          type='checkbox'
          label='Is Active'
          checked={reportData?.IS_ACTIVE === 'Y'}
          onChange={(e) => onChangeHandler(e.target.checked, 'IS_ACTIVE')}
        />
      </Form.Group>

      {reportData?.IS_EXTERNAL !== 'Y' && (
        <Form.Group className='mb-3'>
          <Form.Label htmlFor='appSource'>App Source</Form.Label>
          <Form.Select
            id='APP_SOURCE'
            {...register('APP_SOURCE', {
              required: 'App source is required',
            })}
            className={errors.APP_SOURCE ? 'is-invalid' : ''}>
            <option value=''>Select an app source</option>
            {APP_SOURCES.map((type) => (
              <option
                key={type}
                value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
          {errors.APP_SOURCE && (
            <div className='invalid-feedback'>{errors.APP_SOURCE.message}</div>
          )}
        </Form.Group>
      )}

      <Button
        className='mt-4'
        type='submit'>
        Submit
      </Button>
    </Form>
  );
}

CreateReportForm.propTypes = {
  reports: PropTypes.array.isRequired,
  setReports: PropTypes.func.isRequired,
  fetchReports: PropTypes.func.isRequired,
  data: PropTypes.shape({
    REPORT_NAME: PropTypes.string,
    REPORT_URL: PropTypes.string,
    IS_EXTERNAL: PropTypes.oneOf(['Y', 'N']),
    IS_ACTIVE: PropTypes.oneOf(['Y', 'N']),
    APP_SOURCE: PropTypes.string,
    ID: PropTypes.number,
    UPDATED_BY: PropTypes.string,
  }),
  categories: PropTypes.array.isRequired,
  actionType: PropTypes.oneOf(['create', 'edit']),
  handleClose: PropTypes.func.isRequired,
};

CreateReportForm.defaultProps = {
  data: {
    REPORT_NAME: '',
    REPORT_URL: '',
    IS_EXTERNAL: 'N',
    IS_ACTIVE: 'Y',
    APP_SOURCE: '',
  },
  actionType: 'create',
};
