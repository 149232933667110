import React from 'react';
import { Table } from 'react-bootstrap';
import Button from '../../../components/ui/button/Button';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { deleteUser } from '../../../services/manage-user.service';

const deleteUserHandler = async (unixId, fetchUsers) => {
  try {
    const { error, message } = await deleteUser(unixId);
    if (error) {
      toast.error(message);
      return;
    }
    fetchUsers();
    toast.success(message);
  } catch (error) {
    toast.error(error.message);
  }
};

const confirmDelete = (unixId, fetchUsers) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    showCancelButton: true,
    showCloseButton: true,
    closeButtonHtml:
      '<i class="btn-close close-button" style="margin-right:10px !important;"></i>',
    confirmButtonText: 'Yes, delete it!',
  }).then(async (result) => {
    if (result.isConfirmed) {
      await deleteUserHandler(unixId, fetchUsers);
    }
  });
};

export default function UserList({ users, fetchUsers, setEditData }) {
  return (
    <Table
      hover
      className='mt-4'>
      <thead>
        <tr>
          <th className='text-nowrap'>Actions</th>
          <th className='text-nowrap'>Unix Id</th>
          <th className='text-nowrap'>Name</th>
          <th className='text-nowrap'>Email</th>
          <th className='text-nowrap'>Role</th>
        </tr>
      </thead>
      <tbody>
        {users.length === 0 && (
          <tr>
            <td
              colSpan={4}
              className='text-center'>
              No users found
            </td>
          </tr>
        )}
        {users?.map((user) => (
          <tr key={user.UNIX_ID}>
            <td>
              <div className='d-flex gap-1'>
                <Button
                  className='me-1'
                  onClick={() => setEditData(user)}
                  title='Edit'>
                  <i className='icon icon__edit me-0'></i>
                </Button>
                <Button
                  onClick={() => confirmDelete(user.UNIX_ID, fetchUsers)}
                  title='Delete'>
                  <i className='icon icon__delete me-0'></i>
                </Button>
              </div>
            </td>
            <td>{user.UNIX_ID}</td>
            <td>{user.NAME}</td>
            <td>{user.EMAIL}</td>
            <td>{user?.ROLE_CODE || 'No roles assigned'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      UNIX_ID: PropTypes.string.isRequired,
      NAME: PropTypes.string.isRequired,
      EMAIL: PropTypes.string.isRequired,
      ROLE_CODE: PropTypes.string,
      ROLE_DESC: PropTypes.string,
    }),
  ).isRequired,
  fetchUsers: PropTypes.func.isRequired,
  setEditData: PropTypes.func.isRequired,
};
