// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O9DK3tXpaMaTD3LLYI3L{background:var(--color-tertiary-blue) 0% 0% no-repeat padding-box;border-radius:4px;font:normal normal bold 14px/16px Gene Sans;letter-spacing:0px;color:var(--color-white);border:none}.O9DK3tXpaMaTD3LLYI3L:hover,.O9DK3tXpaMaTD3LLYI3L:active{background:var(--color-secondary-blue) !important}.O9DK3tXpaMaTD3LLYI3L:disabled{background:var(--color-light-brown) !important;color:var(--color-dark-brown) !important}.UKYBSYP721kfYdWazTpi{padding:6px 7px !important;width:33px;height:30px}`, "",{"version":3,"sources":["webpack://./src/components/ui/button/Button.module.scss"],"names":[],"mappings":"AAAA,sBACE,iEAAA,CACA,iBAAA,CACA,2CAAA,CACA,kBAAA,CACA,wBAAA,CACA,WAAA,CAGF,yDAEE,iDAAA,CAGF,+BACE,8CAAA,CACA,wCAAA,CAGF,sBACE,0BAAA,CACA,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `O9DK3tXpaMaTD3LLYI3L`,
	"btn-icon": `UKYBSYP721kfYdWazTpi`
};
export default ___CSS_LOADER_EXPORT___;
