import PropTypes from 'prop-types';
import React from 'react';
import classes from './Iframe.module.scss';
export default function Iframe({ src }) {
  return (
    <iframe
      className={classes.iframe}
      src={src}></iframe>
  );
}
Iframe.propTypes = {
  src: PropTypes.string.isRequired,
};
