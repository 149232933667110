import React, { useState } from 'react';
import Header from './header/Header';
import SideBar from './sidebar/SideBar';
import { useApp } from '../../hooks/useApp';
import Searchbar from '../search/Searchbar';

const Layout = () => {
  const { menus } = useApp();
  const [show, setShow] = useState(false);
  const closeHandler = () => {
    setShow(false);
  };
  return (
    <div className='d-flex'>
      <Header setSearchHandler={setShow} />
      <SideBar items={menus.list} />
      <Searchbar
        show={show}
        onClose={closeHandler}
      />
    </div>
  );
};

export default Layout;
