import api from '../../api';
import { createErrorResponse, createSuccessResponse } from '../utils';
export async function getCategories({ page = 1, pageSize = 10 }) {
  try {
    const categoryResponse = await api.get(
      `/categories?page=${page}&pageSize=${pageSize}`,
    );
    if (categoryResponse.data.status === 'error') {
      return createErrorResponse(categoryResponse);
    }
    return createSuccessResponse(categoryResponse, 'categories');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function createCategory(data) {
  try {
    const categoryResponse = await api.post(`/categories`, {
      ...data,
    });
    if (categoryResponse.data.status === 'error') {
      return createErrorResponse(categoryResponse);
    }
    return createSuccessResponse(categoryResponse, 'category');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function updateCategory(data) {
  try {
    const categoryResponse = await api.put(`/categories`, data);
    if (categoryResponse.data.status === 'error') {
      return createErrorResponse(categoryResponse);
    }
    return createSuccessResponse(categoryResponse, 'category');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}

export async function deleteCategory(id) {
  try {
    const categoryResponse = await api.delete(`/categories/${id}`);
    if (categoryResponse.data.status === 'error') {
      return createErrorResponse(categoryResponse);
    }
    return createSuccessResponse(categoryResponse, 'category');
  } catch (e) {
    console.log(e.message);
    return createErrorResponse(e);
  }
}
