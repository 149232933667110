import React from 'react';
import HomePage from '../pages/home/Home';
import { Navigate, Route } from 'react-router-dom';
import { APP_URL, BASE_URL } from '../constants';
export default function PublicRoutes() {
  const publicRoutes = [
    { path: 'home', element: <HomePage /> },
    { path: '/', element: <Navigate to='home' /> },
  ];
  return publicRoutes.map((p) => (
    <React.Fragment key={p.path}>
      <Route
        path='/'
        element={<Navigate to={`${APP_URL}/home`} />}
      />
      <Route
        path={BASE_URL}
        element={<Navigate to={`${APP_URL}/home`} />}
      />

      <Route
        path={`${APP_URL}/${p.path}`}
        element={p.element}>
        {p?.children?.map((c) => (
          <Route
            key={`${p.path}-${c.path}`}
            path={`${APP_URL}/${p.path}/${c.path}`}
            element={c.element}
          />
        ))}
      </Route>
    </React.Fragment>
  ));
}
