import React, { useEffect, useState } from 'react';
import { Alert, Form, ListGroup } from 'react-bootstrap';
import classes from './AddToFavorites.module.scss';
import { useApp } from '../../hooks/useApp';
import Loading from '../ui/loading/Loading';
import NoData from '../ui/no-data/NoData';
import { editFavorites, getDashboards } from '../../services/app.service';
import Modal from '../ui/modal/Modal';
import { toast } from 'react-toastify';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Icon from '../ui/icon/Icon';

export default function AddToFavorites({ show, onClose }) {
  const { dashboards, user } = useApp();
  const [searchValue, setSearchValue] = useState('');

  const [initialFavorites, setInitialFavorites] = useState({});
  const [list, setList] = useState(dashboards.list);
  const [dashboardGroup, setDashboardGroup] = useState(
    _.groupBy(dashboards.list, 'CATEGORY_NAME'),
  );
  const [updatedFav, setUpdatedFav] = useState({
    add_favorites: [],
    remove_favorites: [],
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const temp = dashboards.list.filter((l) =>
      l.REPORT_NAME.toUpperCase().includes(value.toUpperCase()),
    );
    setList(temp);
    setSearchValue(value);
  };

  // Handles individual checkbox toggle
  const handleFavChange = (e, id) => {
    const isChecked = e.target.checked;
    const temp = { ...updatedFav };

    if (isChecked) {
      temp.remove_favorites = temp.remove_favorites.filter((a) => a !== id);
      if (!initialFavorites[id]) temp.add_favorites.push(id);
    } else {
      temp.add_favorites = temp.add_favorites.filter((a) => a !== id);
      if (initialFavorites[id]) temp.remove_favorites.push(id);
    }
    setUpdatedFav(temp);
  };

  // Handles bulk selection for a category
  const handleCategoryChange = (e, categoryName) => {
    const isChecked = e.target.checked;
    const temp = { ...updatedFav };

    dashboardGroup[categoryName].forEach((dashboard) => {
      const id = dashboard.REPORT_ID;
      if (isChecked) {
        temp.remove_favorites = temp.remove_favorites.filter((a) => a !== id);
        if (!initialFavorites[id]) temp.add_favorites.push(id);
      } else {
        temp.add_favorites = temp.add_favorites.filter((a) => a !== id);
        if (initialFavorites[id]) temp.remove_favorites.push(id);
      }
    });
    setUpdatedFav(temp);
  };

  // Check if a category is fully selected
  const isCategoryChecked = (categoryName) => {
    return dashboardGroup[categoryName].every(
      (dashboard) =>
        updatedFav.add_favorites.includes(dashboard.REPORT_ID) ||
        (!updatedFav.remove_favorites.includes(dashboard.REPORT_ID) &&
          initialFavorites[dashboard.REPORT_ID]),
    );
  };

  // Check if a dashboard is selected
  const isDashboardChecked = (dashboard) => {
    return (
      updatedFav.add_favorites.includes(dashboard.REPORT_ID) ||
      (!updatedFav.remove_favorites.includes(dashboard.REPORT_ID) &&
        initialFavorites[dashboard.REPORT_ID])
    );
  };

  useEffect(() => {
    (async () => {
      try {
        if (dashboards.list.length > 0) return;
        dashboards.setLoading(true);
        const {
          error,
          message,
          dashboards: dashboardsData,
        } = await getDashboards();
        dashboards.setLoading(false);
        if (error) {
          toast.error(message);
          return;
        }

        dashboards.set(dashboardsData);
        setList(dashboardsData);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [user.unixId]);

  useEffect(() => {
    setDashboardGroup(_.groupBy(list, 'CATEGORY_NAME'));
  }, [list]);

  useEffect(() => {
    if (dashboards.list.length > 0) {
      setInitialFavorites(
        dashboards.list.reduce((acc, dashboard) => {
          acc[dashboard.REPORT_ID] = dashboard.IS_FAVORITE === 'Yes';
          return acc;
        }, {}),
      );
    }
  }, [dashboards.list]);

  const onSubmitHandler = async () => {
    const add_favorites = updatedFav.add_favorites.filter(
      (a) => !initialFavorites[a],
    );
    const remove_favorites = updatedFav.remove_favorites.filter(
      (a) => initialFavorites[a],
    );

    if (add_favorites.length === 0 && remove_favorites.length === 0) {
      toast.info('No changes to save.');
      return;
    }

    try {
      const { error, message } = await editFavorites(updatedFav);
      if (error) {
        toast.error(message);
        return;
      }

      const tempDashboards = structuredClone(dashboards.list);
      const tempList = tempDashboards.map((d) => {
        if (updatedFav.add_favorites.includes(d.REPORT_ID)) {
          d.IS_FAVORITE = 'Yes';
        }
        if (updatedFav.remove_favorites.includes(d.REPORT_ID)) {
          d.IS_FAVORITE = 'No';
        }
        return d;
      });

      dashboards.set(tempList);
      setUpdatedFav({
        add_favorites: [],
        remove_favorites: [],
      });
      toast.success(message);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <Modal
      size='xl'
      show={show}
      onHide={onClose}
      onSave={onSubmitHandler}
      title='Add To Favorites'
      disableSave={
        updatedFav.add_favorites.length > 0 ||
        updatedFav.remove_favorites.length > 0
          ? false
          : true
      }
      body={
        <>
          <Form>
            {dashboards.list.length !== 0 && (
              <Form.Group className='mb-3'>
                <Form.Control
                  type='text'
                  placeholder='Search'
                  value={searchValue}
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            <div className={classes.favList}>
              <ListGroup variant='flush'>
                <Loading state={dashboards.loading} />
                {!dashboards.loading && dashboards.list.length === 0 && (
                  <NoData
                    title='No Assigned Dashboards!'
                    subTitle='There are no dashboards assigned to you yet. Contact your administrator for more information.'
                  />
                )}
                {!dashboards.loading &&
                  list.length === 0 &&
                  dashboards.list.length !== 0 && (
                    <NoData
                      title='No results found!'
                      subTitle='There are no results found for the search query'
                    />
                  )}
                {Object.keys(dashboardGroup).map((categoryName, i) => (
                  <React.Fragment key={i}>
                    <h3 className={classes.dashboardGroup}>
                      <Form.Check
                        reverse
                        type='checkbox'
                        id={`category-${i}`}
                        label={categoryName}
                        className={classes.checkbox}
                        checked={isCategoryChecked(categoryName)}
                        onChange={(e) => handleCategoryChange(e, categoryName)}
                      />
                    </h3>
                    <div className={classes.dashboardList}>
                      {dashboardGroup[categoryName].map((dashboard) => (
                        <ListGroup.Item
                          key={dashboard.REPORT_ID}
                          className=''>
                          <Form.Check
                            reverse
                            type='checkbox'
                            id={dashboard.REPORT_ID}
                            label={
                              <>
                                {dashboard.REPORT_NAME}
                                {dashboard.IS_EXTERNAL === 'Y' && (
                                  <Icon
                                    name='externalLink'
                                    color='blue'
                                    className='ms-2'
                                  />
                                )}
                              </>
                            }
                            checked={!!isDashboardChecked(dashboard)}
                            className={classes.checkbox}
                            onChange={(e) =>
                              handleFavChange(e, dashboard.REPORT_ID)
                            }
                          />
                        </ListGroup.Item>
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </ListGroup>
            </div>
          </Form>
          {/* {(updatedFav.add_favorites.length > 0 ||
            updatedFav.remove_favorites.length > 0) && (
            <Alert
              variant='warning'
              className='my-2 py-1 px-2 small'>
              You have unsaved changes to your favorites. Click 'Save Changes'
              to save them.
            </Alert>
          )} */}
        </>
      }
    />
  );
}

AddToFavorites.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
