// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ww63BwZZ09WwZw8ApQxc{display:flex;align-items:center;margin-right:20px}.Q6mtKFbZOSR1L92LwjdP{color:var(--color-white)}`, "",{"version":3,"sources":["webpack://./src/components/search/Search.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CAEF,sBACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta": `Ww63BwZZ09WwZw8ApQxc`,
	"links": `Q6mtKFbZOSR1L92LwjdP`
};
export default ___CSS_LOADER_EXPORT___;
