import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../store/slices/userSlice';
import {
  setFavorites,
  setFavoriteLoading,
  addFavorite,
  deleteFavorite,
} from '../store/slices/favoritesSlice';
import {
  setRecent,
  setRecentLoading,
  addRecent,
  deleteRecent,
} from '../store/slices/recentsSlice';
import {
  setMenus,
  setMenuLoading,
  addMenu,
  deleteMenu,
} from '../store/slices/menusSlice';
import {
  setDashboards,
  setDashboardLoading,
  addDashboard,
  deleteDashboard,
} from '../store/slices/dashboardsSlice';

export const useApp = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const favorites = useSelector((state) => state.favorites);
  const recents = useSelector((state) => state.recents);
  const menus = useSelector((state) => state.menus);
  const dashboards = useSelector((state) => state.dashboards);

  // User Methods
  const setUserData = (user) => dispatch(setUser(user));

  // Favorites Methods
  const setFavoritesData = (favorites) => dispatch(setFavorites(favorites));
  const setFavoritesLoading = (loading) =>
    dispatch(setFavoriteLoading(loading));
  const addFavoriteData = (favorite) => dispatch(addFavorite(favorite));
  const deleteFavoriteData = (id) => dispatch(deleteFavorite(id));

  // Recents Methods
  const setRecentData = (recents) => dispatch(setRecent(recents));
  const setRecentsLoading = (loading) => dispatch(setRecentLoading(loading));
  const addRecentData = (recent) => dispatch(addRecent(recent));
  const deleteRecentData = (id) => dispatch(deleteRecent(id));

  // Menus Methods
  const setMenusData = (menus) => dispatch(setMenus(menus));
  const setMenusLoading = (loading) => dispatch(setMenuLoading(loading));
  const addMenuData = (menu) => dispatch(addMenu(menu));
  const deleteMenuData = (id) => dispatch(deleteMenu(id));

  // Dashboards Methods
  const setDashboardsData = (dashboards) => dispatch(setDashboards(dashboards));
  const setDashboardsLoading = (loading) =>
    dispatch(setDashboardLoading(loading));
  const addDashboardData = (dashboard) => dispatch(addDashboard(dashboard));
  const deleteDashboardData = (id) => dispatch(deleteDashboard(id));

  // Return the state and the action functions
  return {
    user: {
      ...user,
      set: setUserData,
    },
    favorites: {
      ...favorites,
      set: setFavoritesData,
      setLoading: setFavoritesLoading,
      add: addFavoriteData,
      delete: deleteFavoriteData,
    },
    recents: {
      ...recents,
      set: setRecentData,
      setLoading: setRecentsLoading,
      add: addRecentData,
      delete: deleteRecentData,
    },
    menus: {
      ...menus,
      set: setMenusData,
      setLoading: setMenusLoading,
      add: addMenuData,
      delete: deleteMenuData,
    },
    dashboards: {
      ...dashboards,
      set: setDashboardsData,
      setLoading: setDashboardsLoading,
      add: addDashboardData,
      delete: deleteDashboardData,
    },
  };
};
