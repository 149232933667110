import React from 'react';
import classes from './Titlebar.module.scss';
import PropTypes from 'prop-types';

export default function TitleBar({
  title,
  buttons,
  icon,
  dir = 'left',
  onIconClick = () => {},
}) {
  return (
    <div className={classes['titlebar']}>
      {dir === 'left' && icon && (
        <i
          onClick={onIconClick}
          className={`icon icon__${icon} nav-link-icon`}></i>
      )}
      <h3 className='title__medium font__bold'>{title}</h3>
      {dir === 'right' && icon && (
        <i
          onClick={onIconClick}
          className={`icon icon__${icon} nav-link-icon`}></i>
      )}
      {buttons && <div className={classes['action-btns']}>{buttons}</div>}
    </div>
  );
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.node,
  icon: PropTypes.string,
  dir: PropTypes.oneOf(['left', 'right']),
  onIconClick: PropTypes.func,
};

TitleBar.defaultProps = {
  buttons: null,
  icon: null,
  dir: 'left',
  onIconClick: () => {},
};
